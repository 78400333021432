@import "../../../global.scss";

.topicitem_root{
    position: fixed;
    left: 0px;
    right: 0px;
    z-index: 250;
    background-color: transparent;
    overflow: hidden;
}

.topic_stocks_trader {
    box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.3);
    display: flex;
    flex-direction: column;
    position: fixed;
    left: 0px;
    right: 0px;
    width: 100vw;
    z-index: 350;
    transition: all 0.3s ease-in-out;
    background-color: white;
    overflow: hidden;

    > div:nth-child(1){
        min-height: 45px;
        background-color: #3F3D56;
        border-top: 1px solid rgba(245,245,245,0.2);
        display: flex;
        flex-direction: row;
        align-items: center;
        padding-left: 20px;
        padding-right: 20px;
        color:white;
        >label{
            margin-top: auto;
            margin-bottom: auto; 
        }
        >:nth-child(2){
            margin-left: auto;
            margin-right: 20px;
        }
        >:nth-child(3){
            margin-right: 20px;
            font-size: 14px;
        }
        >i{
            margin-top: auto;
            margin-bottom: auto;
            cursor: pointer;
        }
        >.minus{
            margin-bottom: 18px;
            margin-right: 14px;
        }
        >.maximize{
            margin-right: 14px;
        }
    }

    >div:nth-child(2){
        display: flex;
        flex-direction: row;
        flex: 1;
        margin-top: 10px;
    overflow-y: scroll;
    
        > :nth-child(1) {
            display: flex;
            flex-direction: column;
            border-right: 0.3px solid rgba(0, 0, 0, 0.3);
            > :nth-child(1) {
                padding-top: 10px;
                padding-left: 20px;
                color: $app_color;
            }
            p{
                font-size: 13px;
                color: $app_color;
                padding-left: 20px;
                padding-top: 10px;
            }
            > :nth-child(3) {
                width: 240px;
                display: flex;
                flex-direction: column;
                flex: 1;
                > li {
                    padding-top: 4px;
                    padding-bottom: 4px;
                    padding-left: 40px;
                    padding-right: 10px;
                    font-size: 13px;
                    cursor: pointer;
                    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
                    color: "black";
                    font-weight: bold;
                }
                > .start_quiz_button {
                    background-color: $app_color;
                    color: white;
                    box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.3);
                    font-size: 14px;
                    line-height: 25px;
                    padding:0px;
                    margin-top: auto;
                    text-align: center;
                    padding-top: 8px;
                }
            }
        }
    
        > :nth-child(2) {
            flex: 1;
            padding: 20px 40px;
            overflow-y: scroll;
        }
    }
}