@import '../../global.scss';
.cust_alert{
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0,0,0,0.5);
    display: flex;
    flex-direction: row;
    justify-content: center;
    z-index: 150;
    padding-top: 30px;

    .cust_alert_view{
        width: 300px;
        height: 90px;
        background-color: white;
        box-shadow: 0px 0px 3px rgba(0,0,0,0.3);

        >div:nth-child(1){
            height: 35px;
            background-color: $warning;
            display: flex;
            flex-direction: row;
            align-items: center;
            >:nth-child(1){
                margin: 9px;
            }

            >:nth-child(2){
                padding-top: 8px;
            }

            >:nth-child(3){
                margin-left: auto;
                margin-right: 10px;
                margin-top: 12px;
            }
        }

        >div:nth-child(2){
            display: flex;
            flex-direction: column;
            align-items: center;
            flex: 1;
            padding: 10px;
            font-size: 14px;
        }
    }
}