.showActiveUsers{
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding-top: 20px;
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    z-index: 9999;
    background-color: rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(3px);
    >div {
        box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.3);
        border-radius: 2px;
        background-color: white;
        width: 500px;
        // padding: 10px 30px;
        // top
        >div:nth-child(1) {
            display: flex;
            background-color: #1b1b38;
            color: white;
            height: 45px;
            align-items: center;
            padding-left: 10px;
            padding-right: 10px;
            >:nth-child(2){
                margin-left: auto;
                margin-right: 24px;
            }
            >:nth-child(3) {
                margin-left: auto;
                cursor: pointer;
                opacity: 0.6;
                font-size: 14px;
            }
        }

        // table
        >div:nth-child(2){
            padding: 5px 30px;
            >table {
                width: 100%;
                >thead {
                    >tr {
                        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
                        padding-bottom: 6px;
                        >th {
                            text-align: center;
                            padding-right: 20px;
                        }
                        >:nth-child(1) {
                            padding-right: 20px;
                        }
                    }
                }
                >tbody {
                    >tr {
                        height: 40px;
                        >td {
                            padding-right: 20px;
                        }
                    }
                }
            }
        }
    }
}