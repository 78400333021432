.select2-container--default
.select2-selection--single
.select2-selection__arrow
b {
margin-top: 6px;
}
.select2-container--default .select2-selection--single {
height: 47px;
}
.assets-modal .select2-container {
width: 91% !important;
}
/* .assets-modal .modal-body{
height: 294px;
} */
.modal-footer {
justify-content: normal;
display: initial;
}

.clearfix:after {
visibility: hidden;
display: block;
font-size: 0;
content: " ";
clear: both;
height: 0;
}
.upload-files .file-upload-default {
position: absolute;
opacity: 0;
width: 100% !important;
height: 47px;
cursor: pointer;
}

.form-check .form-check-label input[type="checkbox"] + .input-helper:before {
content: "";
width: 18px;
height: 18px;
border-radius: 2px;
border: solid #405189;
border-width: 2px;
-webkit-transition: all;
-moz-transition: all;
-ms-transition: all;
-o-transition: all;
transition: all;
transition-duration: 0s;
-webkit-transition-duration: 250ms;
transition-duration: 250ms;
}
.upload-files .fileupload-input {
position: absolute;
height: 45px;
width: 100%;
opacity: 0;
}
/* check box */

/* The check */
.check {
display: block;
position: relative;
padding-left: 25px;
margin-bottom: 12px;
padding-right: 15px;
cursor: pointer;
font-size: 18px;
-webkit-user-select: none;
-moz-user-select: none;
-ms-user-select: none;
user-select: none;
}

/* Hide the browser's default checkbox */
.check input {
position: absolute;
opacity: 0;
cursor: pointer;
}

/* Create a custom checkbox */
.checkmark {
position: absolute;
top: 3px;
left: 0;
height: 18px;
width: 18px;
border-color: #a9a7a7;
border-style: solid;
border-width: 2px;
}

/* When the checkbox is checked, add a blue background */
.check input:checked ~ .checkmark {
border-color: #3da5f4;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
content: "";
position: absolute;
display: none;
}

/* Show the checkmark when checked */
.check input:checked ~ .checkmark:after {
display: block;
}

/* Style the checkmark/indicator */
.check .checkmark:after {
left: 5px;
top: 1px;
width: 5px;
height: 10px;
border: solid;
border-color: #3da5f4;
border-width: 0 3px 3px 0;
-webkit-transform: rotate(45deg);
-ms-transform: rotate(45deg);
transform: rotate(45deg);
}

.asset-url button {
background: #22243b;
color: #c3bdbd;
border: #ccc;
}

.card-body {
/* min-height: calc(100vh - 124px); */
}
#snackbar {
border-radius: 2px;
padding: 8px 12px;
height: 37px;
}
.switch-height{
min-height: 24px;
}
.progress-md{
height: 8px!important;
}
/* Unanswer*/
.time-indicator{
    line-height: 2.4;
    font-weight: 600;
    line-height: 2.4;
    font-weight: 600;
    opacity: 1;
    background-color: #2b7a77;
    border-radius: 18px;
    width: 90px;
    text-align: center;
    color: #feffff;
}

.badge.badge-pill{
    padding: .375rem .5625rem;

}
/* confirm modal */
.confirm-modal .modal-dialog{
    max-width: 300px;;
}
.confirm-modal .modal-dialog .modal-content .modal-header {
    padding: 10px 11px;
}
.confirm-modal .modal-dialog .modal-content .modal-body {
    padding: 15px 16px;
}
.confirm-modal .modal-dialog .modal-content .modal-footer {
    padding: 10px 11px;
}
.confirm-modal {
    max-width:400px!important;
    margin: 0 auto;;
}
.submit-btn {
    margin-top: 25px;
    display: block;
    float: right;
}

.answered-timer{
    background-color: #2b7a77;
    border-radius: 19px;
    overflow: hidden;
    max-width: 90px;
    padding: 8px 10px;
    text-align: center;
    line-height: 12px;
    font-size: 13px;
    font-weight: 700px;
    color: #ffffff;
    height: 26px;
    margin-top: 10px;
}