.quiz-result {
  border: 1px solid #e7680c;
  -webkit-border-radius: 45px 45px 45px 45px;
  border-radius: 45px 45px 45px 45px;
  padding: 15px;
  background-color: #fff8f3;
}
.quiz-title {
  font-weight: bold;
  color: #1a73e8;
  text-align: center;
  padding: 15px 0;
  font-size: 30px;
}
.result-header {
  font-weight: bold;
  padding: 15px 0;
  font-size: 30px;
  color: grey;
}

.data-result {
  padding: 10px 24px;
  background-color: #e0cbbd;
  color: rgb(36, 33, 33);
}
.data-result span {
  font-size: 19px;
  text-align: center;
}
.result-pass {
  color: green;
}
.result-fail {
  color: rgb(221, 27, 27);
}
.verdict-text {
  font-size: 30px;
  color: #1a73e8;
  text-align: center;
}
.header-style {
  background-color: blue;
}
.report-card-title {
  background-color: #1a73e8;
  padding: 10px 10px 10px 19px;
  color: #fff;
  font-weight: bold;
  font-size: 21px;
  margin-top: 50px;
}
.result-container {
  margin: 10px;
  width: 70%;
  position: absolute;
  z-index: 15;
  top: 20%;
  left: 20%;
}
.report-card-fail {
  text-align: center;
  font-size: 20px;
  color: rgb(245, 3, 3);
  font-family: "Roboto", sans-serif;
}
.report-card-pass {
  text-align: center;
  font-size: 22px;
  color: rgb(35, 121, 32);
  font-family: "Roboto", sans-serif;
}
.result-box {
  background-color: #e0cbbd;
}
