::-webkit-scrollbar{
  display: none;
}

.togglePanel{
    position: fixed;
    bottom: 46px;
    z-index: 150;
    width: 100vw;
    height: 34px;
    border-top: 1px solid rgba(0,0,0,0.2);
    overflow: hidden;
    display: flex;
    background-color: white;
  }
  
  .togglePanel::-webkit-scrollbar{
    display: none;
  }
  
  .togglePanel >div{
    width: 200px;
    margin-right: 10px;
    box-shadow: 0px 0px 3px rgba(0,0,0,0.5);
    font-size: 13px;
    display: flex;
    padding-left: 10px;
  }
  
  .togglePanel >div >p{
    margin-top: 4px;
  }
  
  .togglePanel >div:hover{
    cursor: pointer;
    opacity: 0.8;
  }
  
  .togglePanel >div >i{
    margin-left: auto;
    margin-top: auto;
    margin-bottom: auto;
    margin-right: 10px;
  }