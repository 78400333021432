.list-item {
  font-size: 18px;
  margin-left: 10px;
  list-style-type: disc;
  line-height: 2.6;
  color: black;
}
.sublist-item {
  font-size: 16px;
  margin-left: 50px;
  list-style-type: disc;
  line-height: 1.9;
  color: black;
}
.sub-sublist-item {
  font-size: 14px;
  margin-left: 90px;
  list-style-type: disc;
  line-height: 1.9;
  color: black;
}
.topic-heading {
  /* text-decoration: underline; */
  font-weight: bold;
  margin-bottom: 10px;
}
.topic-name {
  text-align: center;
  font-weight: bold;
  color: #1a73e8;
  font-size: 30px;
  margin-bottom: 20px;
  display: none;
}
.bottom-margin {
  padding-bottom: 100px;
}
.page-number {
  font-weight: 700;
  position: "absolute";
  float: right;
  margin-top: 35px;
  margin-right: 10px;
}

.curriculum-page {
  padding-top: 20px !important;
  padding-left: 30px !important;
  padding-right: 30px !important;
}

.topic-heading {
  text-align: center;
  font-size: 24px;
}
/* .list-item {
  font-weight: 500;
} */
