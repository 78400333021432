@import url(https://fonts.googleapis.com/css2?family=Roboto&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto&display=swap);
::-webkit-scrollbar{
  display: none;
}

.togglePanel{
    position: fixed;
    bottom: 46px;
    z-index: 150;
    width: 100vw;
    height: 34px;
    border-top: 1px solid rgba(0,0,0,0.2);
    overflow: hidden;
    display: -ms-flexbox;
    display: flex;
    background-color: white;
  }
  
  .togglePanel::-webkit-scrollbar{
    display: none;
  }
  
  .togglePanel >div{
    width: 200px;
    margin-right: 10px;
    box-shadow: 0px 0px 3px rgba(0,0,0,0.5);
    font-size: 13px;
    display: -ms-flexbox;
    display: flex;
    padding-left: 10px;
  }
  
  .togglePanel >div >p{
    margin-top: 4px;
  }
  
  .togglePanel >div:hover{
    cursor: pointer;
    opacity: 0.8;
  }
  
  .togglePanel >div >i{
    margin-left: auto;
    margin-top: auto;
    margin-bottom: auto;
    margin-right: 10px;
  }
.baner{
    width: 100vw;
    height: 300px;
    display: -ms-flexbox;
    display: flex;
}

.baner .left{
    min-width: 66vw;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
        align-items: center;
    margin-top: 40px;
    margin-bottom: 20px;
}

.baner .left .scroll_view{
    -ms-flex:1 1;
        flex:1 1;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
        align-items: center;
    overflow: hidden;
}

.baner .left .scroll_view .left-container{
    display: -ms-flexbox;
    display: flex;
    min-width: 100%;
    min-height: 100%;
    padding-left: 20px;
    padding-right: 20px;
    position: relative;
    -webkit-transition: all 0.8s ease-in-out;
    transition: all 0.8s ease-in-out;
}

.baner .left .scroll_view .left-container .left-container-left{
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
        flex-direction: column;
    -ms-flex-pack: end;
        justify-content: flex-end;
    padding-right: 20px;
    -ms-flex: 1 1;
        flex: 1 1;
}

.baner .left .scroll_view .left-container .left-container-left .baner_heading{
    color:#ff6929;
    font-size: 24px;
    font-weight: bold;
    text-shadow: 0px 0px 3px rgba(0,0,0,0.3);
    line-height: 30px;
    margin-bottom: 8px;
}

.baner .left .scroll_view .left-container .left-container-left p{
    color: white;
}

.baner .left .scroll_view .left-container .left-container-left >div:nth-child(3){
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: center;
        justify-content: center;
    margin-top: 30px;
}

.baner .left .scroll_view .left-container .left-container-left >div:nth-child(3) .btn-text{
    font-size: 16px;
    margin-right: 20px;
    margin-top: 10px;
}

.baner .left .ion-icon{
    color: white;
    font-size: 50px;
    font-weight: bold;
    opacity: 0.6;
    cursor: pointer;
}

.baner .left .scroll_view .left-container .left-container-right{
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: center;
        justify-content: center;
    -ms-flex-align: center;
        align-items: center;
}

.baner .left .scroll_view .left-container .left-container-right img{
    width: 170px;
    height: 150px;
}

/* right */

.baner .right{
    min-width: 34vw;
    background-color: rgba(0,0,0,0.1);
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
        flex-direction: column;
    padding: 20px;
    position: relative;
}

.baner .ft_video_controllers{
    position: absolute;
    top: 28px;
    left: 20px;
    right: 20px;
    bottom: 20px;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
        flex-direction: column;
    -ms-flex-pack: center;
        justify-content: center;
    -ms-flex-align: center;
        align-items: center;
    background-color: black;
    cursor: pointer;
}

.baner .ft_video_controllers .play_button{
    width: 100px;
    height: 100px;
    color: #ff4d00a4;
}

.baner .ft_video_controllers .play_button:hover{
    color: #ff4d00d7;
}

.baner .ft_video_controllers p{
    color: white;
    opacity: 0.8;
    font-size: 13px;
}

.baner .ft_video_controllers label{
    color: white;
    font-weight: 400;
    opacity: 0.9;
}

/* .baner .right .baner_heading{
    color:white;
    font-size: 20px;
    text-shadow: 0px 0px 3px rgba(0,0,0,0.3);
}

.baner .right p{
    color:white;
    font-size: 13px;
} */
.closeCurrentSession {
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background-color: rgba(0, 0, 0, 0.05);
  -webkit-backdrop-filter: blur(3px);
          backdrop-filter: blur(3px);
  z-index: 9999;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: start;
      align-items: flex-start;
  padding-top: 20px; }
  .closeCurrentSession > div {
    background-color: white;
    border-radius: 6px;
    padding-top: 25px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 10px;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
        flex-direction: column;
    box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.3); }
    .closeCurrentSession > div > div {
      display: -ms-flexbox;
      display: flex;
      -ms-flex-direction: row-reverse;
          flex-direction: row-reverse;
      -ms-flex-align: center;
          align-items: center; }
      .closeCurrentSession > div > div > label {
        font-size: 14px; }
      .closeCurrentSession > div > div > button {
        margin-left: 10px; }

.print-content-container{
    width: 100%;
    margin: 0 auto;
    padding: 5% 4% 0 4%;
}
.qr-code{
    text-align: center;
    margin: 0 auto;
    display: block;
    margin-top: 150px;
}
.invite-details{
    font-size: 28px;
    margin-bottom: 15px;
}
.invite-text{
    font-size: 28px;
    text-align: center;
}
.print-text span {
    font-size: 28px;
    text-align: center;
    }

.print-class-name {
    color: black;
    font-size: 20px;
}
.print-class-decription {
    color: black;
    text-align: left;
    font-size: 20px;
    display: inline-block;
}

.print-date{
    font-size: 20px;
}
.classCreated {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100vh;
  -webkit-backdrop-filter: blur(6px);
          backdrop-filter: blur(6px);
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 1;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande", "Lucida Sans Unicode", Geneva, Verdana, sans-serif; }
  .classCreated > div {
    width: 700px;
    background-color: white;
    box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.3);
    display: -ms-flexbox;
    display: flex; }
    .classCreated > div .screen1 {
      -ms-flex: 1 1;
          flex: 1 1;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-direction: column;
          flex-direction: column; }
      .classCreated > div .screen1 > div:nth-child(1) {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-direction: row;
            flex-direction: row;
        -ms-flex-pack: center;
            justify-content: center;
        -ms-flex-align: center;
            align-items: center;
        height: 50px;
        background-color: #1b1b38;
        padding-top: 6px; }
        .classCreated > div .screen1 > div:nth-child(1) > img {
          margin-bottom: 2px;
          margin-left: 10px; }
        .classCreated > div .screen1 > div:nth-child(1) > :nth-child(2) {
          font-size: 16px;
          color: white;
          font-weight: 700;
          margin-left: auto;
          margin-right: 18px; }
    .classCreated > div .screen2 {
      -ms-flex: 1 1;
          flex: 1 1;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-direction: column;
          flex-direction: column;
      -ms-flex-align: center;
          align-items: center;
      padding: 20px 20px; }
      .classCreated > div .screen2 > label:nth-child(1) {
        font-size: 20px;
        font-weight: 500; }
      .classCreated > div .screen2 > label:nth-child(2) {
        font-size: 15px;
        margin-top: 8px;
        margin-bottom: 25px;
        text-align: center;
        opacity: 0.7; }
      .classCreated > div .screen2 > div:nth-child(3) {
        height: 60px;
        width: 400px;
        border: 0.8px solid rgba(0, 0, 0, 0.3);
        border-radius: 8px;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-direction: row;
            flex-direction: row;
        -ms-flex-align: center;
            align-items: center;
        background-color: white;
        box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.3);
        margin-bottom: auto; }
        .classCreated > div .screen2 > div:nth-child(3) > textarea {
          -ms-flex: 1 1;
              flex: 1 1;
          padding-top: 8px;
          border: 0px;
          padding-left: 10px;
          padding-right: 10px;
          border: 0.8px solid rgba(0, 0, 0, 0.3);
          border-top-left-radius: 8px;
          border-bottom-left-radius: 8px;
          font-size: 13px; }
        .classCreated > div .screen2 > div:nth-child(3) > i {
          height: 100%;
          display: -ms-flexbox;
          display: flex;
          -ms-flex-align: center;
              align-items: center;
          padding-left: 15px;
          padding-right: 15px;
          background-color: rgba(0, 0, 0, 0.1);
          font-size: 14px;
          cursor: pointer; }
      .classCreated > div .screen2 > label:nth-child(4) {
        margin-top: 20px;
        margin-bottom: 20px;
        opacity: 0.4; }
      .classCreated > div .screen2 > div:nth-child(5) {
        height: 40px;
        width: 400px;
        border: 0.8px solid rgba(0, 0, 0, 0.3);
        border-radius: 8px;
        padding-left: 10px;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-direction: row;
            flex-direction: row;
        -ms-flex-align: center;
            align-items: center;
        white-space: nowrap;
        background-color: white;
        box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.3);
        margin-bottom: auto; }
        .classCreated > div .screen2 > div:nth-child(5) > label {
          font-size: 13px;
          overflow: hidden;
          padding-top: 4px;
          opacity: 0.9; }
        .classCreated > div .screen2 > div:nth-child(5) > div {
          margin-left: auto;
          background-color: rgba(0, 0, 0, 0.1);
          padding-right: 20px;
          padding-left: 20px;
          height: 100%;
          border-top-right-radius: 8px;
          border-bottom-right-radius: 8px;
          display: -ms-flexbox;
          display: flex;
          -ms-flex-pack: center;
              justify-content: center;
          -ms-flex-align: center;
              align-items: center;
          cursor: pointer; }
          .classCreated > div .screen2 > div:nth-child(5) > div > i {
            font-size: 13px; }
          .classCreated > div .screen2 > div:nth-child(5) > div:hover {
            opacity: 0.4; }
      .classCreated > div .screen2 > div:nth-child(6) {
        width: 100%;
        height: 45px;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-direction: row-reverse;
            flex-direction: row-reverse;
        -ms-flex-align: center;
            align-items: center; }
        .classCreated > div .screen2 > div:nth-child(6) > div {
          border: 1px solid rgba(0, 0, 0, 0.7);
          color: rgba(0, 0, 0, 0.7);
          border-radius: 10px;
          box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.5);
          font-size: 12px;
          font-weight: bold;
          padding: 5px 16px;
          cursor: pointer; }


.help-icon {
    margin-top: 4px;
    color: white;
}

.help-icon-view {
    position: absolute;
    background-color: white;
    z-index: 100;
    box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.4);
    font-size: 13px;
    padding: 6px 20px;
    border-radius: 6px;
    top: 32px;
    left:225px;
    display: none;
}

.help-icon:hover ~.help-icon-view {
    display: -ms-flexbox;
    display: flex;
}

.help-icon-view:hover{
    display: -ms-flexbox;
    display: flex;
}

.class-cards {
  background-color: #fff;
  margin: auto;
  width: 90%;
  height: 144px;
  position: relative;
  box-shadow: 0 0 15px 5px #ebebeb;
  margin-bottom: 40px;
  border-radius: 15px;
  border: 2px solid #1a73e8;
}
.class-container {
  /* height:60vh; */
}
.ic-del {
  position: absolute;
  font-size: 10px;
  right: -16px;
  top: -8px;
  margin: 15px;
  padding: 8px;
  margin-right: 23px;
  margin-top: 15px;
  background-color: #1a73e8 !important;
  border-radius: 5px;
  color: #ffffff;
}

.class-name-wrap {
  position: relative;
  padding: 10px;
  top: 33%;
  -webkit-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
          transform: translateY(-50%);
  text-align: left;
  height: 140px;
  font-family: "Times New Roman", Times, serif;
}

.class-name-wrap h4 {
  color: #afaaaa;
  font-size: 20px;
}
.add-new-btn {
  float: right;
  margin: 25px 45px 25px;
  background-color: #1a73e8 !important;
}

.class-datas {
  color: #afaaaa;
  margin: 0;
  position: absolute;
  width: 90%;
  padding-bottom: 6px;
  font-size: 15px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  text-align: left;
}
.item-data {
  font-weight: bold;
  color: black;
}
.class-btn {
  position: absolute;
  left: 0px;
}
.year-filter {
  width: 160px;
  position: absolute;
  left: 500px;
  top: 80px;
}

.card-footer {
  padding: 0.4rem 0.75rem;
  background-color: #1a73e8;
  border-top: 1px solid rgba(0, 0, 0, 0.125);
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
}

.text-muted {
  color: #f5f5f5;
}

.card-body:hover {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  background-color: #f5f5f5;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
}

.add-new-class {
  -ms-flex-align: center;
      align-items: center;
  border-radius: 8px;
  box-sizing: border-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  -ms-flex-positive: 1;
      flex-grow: 1;
  -ms-flex-pack: center;
      justify-content: center;
}

.card-title {
  margin-top: 6px;
  margin-bottom: 0px;
}

.dates {
  margin-top: 0px;
}

#card {
  z-index: 1;
}

._addClass{
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
      flex-direction: row;
  -ms-flex-align: center;
      align-items: center;
  margin-left: auto;
  margin-right: 20px;
  background-color: white;
  color: #0056c7;
  padding: 4px 15px;
  border-radius: 8px;
  box-shadow: 0px 0px 3px rgba(0,0,0,0.3);
  z-index: 100;
  cursor: pointer;
}

.class_list_item_info_icon {
  padding-left: 10px;
  font-size: 18.5px;
  color: rgba(0, 0, 0, 0.9);
  cursor: pointer;
  position: absolute;
  top: 0px;
  right: 20px;
}

.filter_dropdown{
  height:40px;
  box-shadow: 0px 0px 3px rgba(0,0,0,0.3);
  border-radius:10px;
  /* position:absolute;
  top:85px;
  right:13px; */
  display:-ms-flexbox;
  display:flex;
  -ms-flex-direction:row;
      flex-direction:row;
  -ms-flex-align:center;
      align-items:center;
  background-color: #0056c7;
  color: white;
  border: 0.4px solid rgba(245,245,245,0.5);
  z-index: 100;
}

.filter_dropdown_1{
  height:40px;
  border: 1px solid #1A73E8;
  box-shadow: 0px 0px 3px rgba(0,0,0,0.3);
  border-radius:10px;
  /* position:absolute;
  top:85px;
  right:126px; */
  display:-ms-flexbox;
  display:flex;
  -ms-flex-direction:row;
      flex-direction:row;
  -ms-flex-align:center;
      align-items:center;
  grid-gap: 20px;
  gap: 20px;
  padding: 0px 20px;
  font-size: 14px;
  padding-top: 6px;
  background-color: #0056c7;
  color: white;
  border: 0.4px solid rgba(245,245,245,0.5);
  z-index: 100;
}

.filter_dropdown_1_item{
  cursor: pointer;
  font-weight: 500;
  color: white;
}

.filter_dropdown_1_item:hover{
  text-decoration: underline;
  color: white;
}

.active{
  color: white;
  text-decoration: underline;
}

.filter_dropdown >:nth-child(1) >label{
  color: black;
  font-weight: 450;
}

.filter_dropdown >:nth-child(1) >i{
  color: white;
}

.filter_dropdown:hover >:nth-child(1) >:nth-child(3){
  display:-ms-flexbox;
  display:flex;
}

.filter_dropdown:hover >:nth-child(1)  >:nth-child(2){
  -webkit-transition: 0.3s;
  transition: 0.3s;
  -webkit-transform: rotate(180deg);
      -ms-transform: rotate(180deg);
          transform: rotate(180deg);
}

.filter_dropdown >:nth-child(1) >:nth-child(1){
  -ms-flex:1 1;
      flex:1 1;
  text-align:center;
  font-size:15px;
  margin:10px;
}

.filter_dropdown >:nth-child(1)  >:nth-child(2){
  margin:10px;
  font-size:13px;
  cursor:pointer;
}

.filter_dropdown >:nth-child(1) >:nth-child(3){
  display:none;
  position:absolute;
  min-width: 180px;
  background-color:white;
  border: 1px solid rgba(0,0,0,0.3);
  border-radius:10px;
  padding:20px;
}

.filter_dropdown >:nth-child(1) >:nth-child(3) >ul >li{
  margin-bottom:8px;
  font-size:13px;
  font-weight:bold;
  cursor: pointer;
  opacity: 0.6;
  color: black;
}

.card_search{
  height:40px;
  width: 240px;
  background-color: #0056c7;
  box-shadow: 0px 0px 3px rgba(0,0,0,0.3);
  border-radius:10px;
  /* position:absolute;
  top:85px;
  right:390px; */
  display:-ms-flexbox;
  display:flex;
  -ms-flex-direction:row;
      flex-direction:row;
  -ms-flex-align: center;
      align-items: center;
  border: 0.4px solid rgba(245,245,245,0.5);
  z-index: 100;
  color: white;
}

.card_search >i{
  margin: 10px;
  color: white;
}

.card_search > input{
  background-color: transparent;
  border: 0px;
  outline-width: 0px;
  font-size: 15px;
  color: white;
}

.card_search > input::-webkit-input-placeholder {
  color: white;
  opacity: 1; /* Firefox */
}

.card_search > input::-moz-placeholder {
  color: white;
  opacity: 1; /* Firefox */
}

.card_search > input:-ms-input-placeholder {
  color: white;
  opacity: 1; /* Firefox */
}

.card_search > input::-ms-input-placeholder {
  color: white;
  opacity: 1; /* Firefox */
}

.card_search > input::placeholder {
  color: white;
  opacity: 1; /* Firefox */
}
.select2-container--default
.select2-selection--single
.select2-selection__arrow
b {
margin-top: 6px;
}
.select2-container--default .select2-selection--single {
height: 47px;
}
.assets-modal .select2-container {
width: 91% !important;
}
/* .assets-modal .modal-body{
height: 294px;
} */
.modal-footer {
-ms-flex-pack: normal;
    justify-content: normal;
display: inline;
display: initial;
}

.clearfix:after {
visibility: hidden;
display: block;
font-size: 0;
content: " ";
clear: both;
height: 0;
}
.upload-files .file-upload-default {
position: absolute;
opacity: 0;
width: 100% !important;
height: 47px;
cursor: pointer;
}

.form-check .form-check-label input[type="checkbox"] + .input-helper:before {
content: "";
width: 18px;
height: 18px;
border-radius: 2px;
border: solid #405189;
border-width: 2px;
-webkit-transition: all;
transition: all;
transition-duration: 0s;
-webkit-transition-duration: 250ms;
transition-duration: 250ms;
}
.upload-files .fileupload-input {
position: absolute;
height: 45px;
width: 100%;
opacity: 0;
}
/* check box */

/* The check */
.check {
display: block;
position: relative;
padding-left: 25px;
margin-bottom: 12px;
padding-right: 15px;
cursor: pointer;
font-size: 18px;
-webkit-user-select: none;
-moz-user-select: none;
-ms-user-select: none;
user-select: none;
}

/* Hide the browser's default checkbox */
.check input {
position: absolute;
opacity: 0;
cursor: pointer;
}

/* Create a custom checkbox */
.checkmark {
position: absolute;
top: 3px;
left: 0;
height: 18px;
width: 18px;
border-color: #a9a7a7;
border-style: solid;
border-width: 2px;
}

/* When the checkbox is checked, add a blue background */
.check input:checked ~ .checkmark {
border-color: #3da5f4;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
content: "";
position: absolute;
display: none;
}

/* Show the checkmark when checked */
.check input:checked ~ .checkmark:after {
display: block;
}

/* Style the checkmark/indicator */
.check .checkmark:after {
left: 5px;
top: 1px;
width: 5px;
height: 10px;
border: solid;
border-color: #3da5f4;
border-width: 0 3px 3px 0;
-webkit-transform: rotate(45deg);
-ms-transform: rotate(45deg);
transform: rotate(45deg);
}

.asset-url button {
background: #22243b;
color: #c3bdbd;
border: #ccc;
}

.card-body {
/* min-height: calc(100vh - 124px); */
}
#snackbar {
border-radius: 2px;
padding: 8px 12px;
height: 37px;
}
.switch-height{
min-height: 24px;
}
.progress-md{
height: 8px!important;
}
/* Unanswer*/
.time-indicator{
    line-height: 2.4;
    font-weight: 600;
    line-height: 2.4;
    font-weight: 600;
    opacity: 1;
    background-color: #2b7a77;
    border-radius: 18px;
    width: 90px;
    text-align: center;
    color: #feffff;
}

.badge.badge-pill{
    padding: .375rem .5625rem;

}
/* confirm modal */
.confirm-modal .modal-dialog{
    max-width: 300px;;
}
.confirm-modal .modal-dialog .modal-content .modal-header {
    padding: 10px 11px;
}
.confirm-modal .modal-dialog .modal-content .modal-body {
    padding: 15px 16px;
}
.confirm-modal .modal-dialog .modal-content .modal-footer {
    padding: 10px 11px;
}
.confirm-modal {
    max-width:400px!important;
    margin: 0 auto;;
}
.submit-btn {
    margin-top: 25px;
    display: block;
    float: right;
}

.answered-timer{
    background-color: #2b7a77;
    border-radius: 19px;
    overflow: hidden;
    max-width: 90px;
    padding: 8px 10px;
    text-align: center;
    line-height: 12px;
    font-size: 13px;
    font-weight: 700px;
    color: #ffffff;
    height: 26px;
    margin-top: 10px;
}
.view_leaders_model {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100vh;
  -webkit-backdrop-filter: blur(5);
          backdrop-filter: blur(5);
  background-color: rgba(0, 0, 0, 0.2);
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
  z-index: 1000;
  -webkit-backdrop-filter: blur(3px);
          backdrop-filter: blur(3px); }
  .view_leaders_model > :nth-child(1) {
    background-color: white;
    box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.3);
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
        flex-direction: column;
    min-width: 700px;
    min-height: 400px; }
    .view_leaders_model > :nth-child(1) ::-webkit-scrollbar {
      display: none; }
    .view_leaders_model > :nth-child(1) > :nth-child(1) {
      line-height: 40px;
      background-color: #1b1b38;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-line-pack: center;
          align-content: center;
      padding-left: 20px;
      color: white;
      font-size: 16px; }
    .view_leaders_model > :nth-child(1) > :nth-child(2) {
      display: -ms-flexbox;
      display: flex;
      -ms-flex: 1 1;
          flex: 1 1;
      max-height: 500px;
      overflow: scroll;
      padding: 10px; }
      .view_leaders_model > :nth-child(1) > :nth-child(2) > table {
        width: 100%; }
        .view_leaders_model > :nth-child(1) > :nth-child(2) > table > tr {
          border-bottom: 0.1px solid rgba(0, 0, 0, 0.3); }
          .view_leaders_model > :nth-child(1) > :nth-child(2) > table > tr td {
            text-align: right; }
          .view_leaders_model > :nth-child(1) > :nth-child(2) > table > tr th {
            text-align: right; }
    .view_leaders_model > :nth-child(1) > :nth-child(3) {
      height: 45px;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-direction: row-reverse;
          flex-direction: row-reverse;
      -ms-flex-align: center;
          align-items: center; }
      .view_leaders_model > :nth-child(1) > :nth-child(3) > div {
        margin-right: 20px;
        background-color: rgba(0, 0, 0, 0.1);
        border-radius: 10px;
        box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.3);
        font-size: 12px;
        font-weight: bold;
        padding: 5px 16px;
        color: rgba(0, 0, 0, 0.7);
        cursor: pointer;
        margin-bottom: 8px; }

.cust_alert {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
      flex-direction: row;
  -ms-flex-pack: center;
      justify-content: center;
  z-index: 150;
  padding-top: 30px; }
  .cust_alert .cust_alert_view {
    width: 300px;
    height: 90px;
    background-color: white;
    box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.3); }
    .cust_alert .cust_alert_view > div:nth-child(1) {
      height: 35px;
      background-color: #FFCC00;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-direction: row;
          flex-direction: row;
      -ms-flex-align: center;
          align-items: center; }
      .cust_alert .cust_alert_view > div:nth-child(1) > :nth-child(1) {
        margin: 9px; }
      .cust_alert .cust_alert_view > div:nth-child(1) > :nth-child(2) {
        padding-top: 8px; }
      .cust_alert .cust_alert_view > div:nth-child(1) > :nth-child(3) {
        margin-left: auto;
        margin-right: 10px;
        margin-top: 12px; }
    .cust_alert .cust_alert_view > div:nth-child(2) {
      display: -ms-flexbox;
      display: flex;
      -ms-flex-direction: column;
          flex-direction: column;
      -ms-flex-align: center;
          align-items: center;
      -ms-flex: 1 1;
          flex: 1 1;
      padding: 10px;
      font-size: 14px; }

.showActiveUsers {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: start;
      align-items: flex-start;
  padding-top: 20px;
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  z-index: 9999;
  background-color: rgba(0, 0, 0, 0.1);
  -webkit-backdrop-filter: blur(3px);
          backdrop-filter: blur(3px); }
  .showActiveUsers > div {
    box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.3);
    border-radius: 2px;
    background-color: white;
    width: 500px; }
    .showActiveUsers > div > div:nth-child(1) {
      display: -ms-flexbox;
      display: flex;
      background-color: #1b1b38;
      color: white;
      height: 45px;
      -ms-flex-align: center;
          align-items: center;
      padding-left: 10px;
      padding-right: 10px; }
      .showActiveUsers > div > div:nth-child(1) > :nth-child(2) {
        margin-left: auto;
        margin-right: 24px; }
      .showActiveUsers > div > div:nth-child(1) > :nth-child(3) {
        margin-left: auto;
        cursor: pointer;
        opacity: 0.6;
        font-size: 14px; }
    .showActiveUsers > div > div:nth-child(2) {
      padding: 5px 30px; }
      .showActiveUsers > div > div:nth-child(2) > table {
        width: 100%; }
        .showActiveUsers > div > div:nth-child(2) > table > thead > tr {
          border-bottom: 1px solid rgba(0, 0, 0, 0.1);
          padding-bottom: 6px; }
          .showActiveUsers > div > div:nth-child(2) > table > thead > tr > th {
            text-align: center;
            padding-right: 20px; }
          .showActiveUsers > div > div:nth-child(2) > table > thead > tr > :nth-child(1) {
            padding-right: 20px; }
        .showActiveUsers > div > div:nth-child(2) > table > tbody > tr {
          height: 40px; }
          .showActiveUsers > div > div:nth-child(2) > table > tbody > tr > td {
            padding-right: 20px; }

.contestCreated {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100vh;
  -webkit-backdrop-filter: blur(6px);
          backdrop-filter: blur(6px);
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 1;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande", "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
  overflow: hidden; }
  .contestCreated > div {
    width: 700px;
    background-color: white;
    box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.3);
    display: -ms-flexbox;
    display: flex; }
    .contestCreated > div .screen1 {
      -ms-flex: 1 1;
          flex: 1 1;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-direction: column;
          flex-direction: column; }
      .contestCreated > div .screen1 > div:nth-child(1) {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-direction: row;
            flex-direction: row;
        -ms-flex-pack: center;
            justify-content: center;
        -ms-flex-align: center;
            align-items: center;
        height: 50px;
        background-color: #1b1b38;
        padding-top: 6px; }
        .contestCreated > div .screen1 > div:nth-child(1) > img {
          margin-bottom: 2px;
          margin-left: 10px; }
        .contestCreated > div .screen1 > div:nth-child(1) > :nth-child(2) {
          font-size: 16px;
          color: white;
          font-weight: 700;
          margin-left: auto;
          margin-right: 18px; }
    .contestCreated > div .screen2 {
      -ms-flex: 1 1;
          flex: 1 1;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-direction: column;
          flex-direction: column;
      -ms-flex-align: center;
          align-items: center;
      padding: 20px 20px; }
      .contestCreated > div .screen2 > label:nth-child(1) {
        font-size: 20px;
        font-weight: 500; }
      .contestCreated > div .screen2 > label:nth-child(2) {
        font-size: 15px;
        margin-top: 8px;
        margin-bottom: 25px;
        opacity: 0.7;
        text-align: center; }
      .contestCreated > div .screen2 > div:nth-child(3) {
        height: 60px;
        width: 400px;
        border: 0.8px solid rgba(0, 0, 0, 0.3);
        border-radius: 8px;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-direction: row;
            flex-direction: row;
        -ms-flex-align: center;
            align-items: center;
        background-color: white;
        box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.3);
        margin-bottom: auto; }
        .contestCreated > div .screen2 > div:nth-child(3) > textarea {
          -ms-flex: 1 1;
              flex: 1 1;
          padding-top: 8px;
          border: 0px;
          padding-left: 10px;
          padding-right: 10px;
          border: 0.8px solid rgba(0, 0, 0, 0.3);
          border-top-left-radius: 8px;
          border-bottom-left-radius: 8px;
          font-size: 13px; }
        .contestCreated > div .screen2 > div:nth-child(3) > i {
          height: 100%;
          display: -ms-flexbox;
          display: flex;
          -ms-flex-align: center;
              align-items: center;
          padding-left: 15px;
          padding-right: 15px;
          background-color: rgba(0, 0, 0, 0.1);
          font-size: 14px;
          cursor: pointer; }
      .contestCreated > div .screen2 > label:nth-child(4) {
        margin-top: 20px;
        margin-bottom: 20px;
        opacity: 0.4; }
      .contestCreated > div .screen2 > div:nth-child(5) {
        height: 40px;
        width: 400px;
        border: 0.8px solid rgba(0, 0, 0, 0.3);
        border-radius: 8px;
        padding-left: 10px;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-direction: row;
            flex-direction: row;
        -ms-flex-align: center;
            align-items: center;
        white-space: nowrap;
        background-color: white;
        box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.3);
        margin-bottom: auto; }
        .contestCreated > div .screen2 > div:nth-child(5) > label {
          font-size: 13px;
          overflow: hidden;
          padding-top: 4px;
          opacity: 0.9; }
        .contestCreated > div .screen2 > div:nth-child(5) > div {
          margin-left: auto;
          background-color: rgba(0, 0, 0, 0.1);
          padding-right: 20px;
          padding-left: 20px;
          height: 100%;
          border-top-right-radius: 8px;
          border-bottom-right-radius: 8px;
          display: -ms-flexbox;
          display: flex;
          -ms-flex-pack: center;
              justify-content: center;
          -ms-flex-align: center;
              align-items: center;
          cursor: pointer; }
          .contestCreated > div .screen2 > div:nth-child(5) > div > i {
            font-size: 13px; }
          .contestCreated > div .screen2 > div:nth-child(5) > div:hover {
            opacity: 0.4; }
      .contestCreated > div .screen2 > div:nth-child(6) {
        width: 100%;
        height: 45px;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-direction: row-reverse;
            flex-direction: row-reverse;
        -ms-flex-align: center;
            align-items: center; }
        .contestCreated > div .screen2 > div:nth-child(6) > div {
          border: 1px solid rgba(0, 0, 0, 0.7);
          color: rgba(0, 0, 0, 0.7);
          border-radius: 10px;
          box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.5);
          font-size: 12px;
          font-weight: bold;
          padding: 5px 16px;
          cursor: pointer; }

.class-cards {
  background-color: #fff;
  margin: auto;
  width: 90%;
  height: 184px;
  position: relative;
  box-shadow: 0 0 15px 5px #ebebeb;
  margin-bottom: 40px;
  border-radius: 15px;
  border: 2px solid #1a73e8;
}
.class-container {
  /* height:60vh; */
}
.ic-del {
  position: absolute;
  font-size: 10px;
  right: -16px;
  top: -8px;
  cursor: pointer;
  margin: 15px;
  padding: 8px;
  margin-right: 23px;
  margin-top: 15px;
  background-color: #1a73e8 !important;
  border-radius: 5px;
  color: #ffffff;
}

.class-name-wrap {
  position: relative;
  padding: 10px;
  top: 33%;
  -webkit-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
          transform: translateY(-50%);
  text-align: left;
  cursor: pointer;
  height: 140px;
  font-family: "Times New Roman", Times, serif;
}

.class-name-wrap h4 {
  color: #afaaaa;
  font-size: 20px;
}
.add-new-btn {
  float: right;
  margin: 25px 45px 25px;
  background-color: #1a73e8 !important;
}

.class-datas {
  color: #afaaaa;
  margin: 0;
  position: absolute;
  width: 90%;
  padding-bottom: 6px;
  font-size: 15px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  text-align: left;
}
.item-data {
  font-weight: bold;
  color: black;
}
.class-btn {
  position: absolute;
  left: 0px;
}
.year-filter {
  width: 160px;
  position: absolute;
  left: 500px;
  top: 80px;
}

.card-footer {
  padding: 0.4rem 0.75rem;
  background-color: #1a73e8;
  border-top: 1px solid rgba(0, 0, 0, 0.125);
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
}

.text-muted {
  color: #f5f5f5;
}

.card-body:hover {
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
  background-color: #f5f5f5;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
}

.add-new-class {
  -ms-flex-align: center;
      align-items: center;
  border-radius: 8px;
  box-sizing: border-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  -ms-flex-positive: 1;
      flex-grow: 1;
  -ms-flex-pack: center;
      justify-content: center;
}

.select2-container--default
.select2-selection--single
.select2-selection__arrow
b {
margin-top: 6px;
}
.select2-container--default .select2-selection--single {
height: 47px;
}
.assets-modal .select2-container {
width: 91% !important;
}
/* .assets-modal .modal-body{
height: 294px;
} */
.modal-footer {
-ms-flex-pack: normal;
    justify-content: normal;
display: inline;
display: initial;
}

.clearfix:after {
visibility: hidden;
display: block;
font-size: 0;
content: " ";
clear: both;
height: 0;
}
.upload-files .file-upload-default {
position: absolute;
opacity: 0;
width: 100% !important;
height: 47px;
cursor: pointer;
}

.form-check .form-check-label input[type="checkbox"] + .input-helper:before {
content: "";
width: 18px;
height: 18px;
border-radius: 2px;
border: solid #405189;
border-width: 2px;
-webkit-transition: all;
transition: all;
transition-duration: 0s;
-webkit-transition-duration: 250ms;
transition-duration: 250ms;
}
.upload-files .fileupload-input {
position: absolute;
height: 45px;
width: 100%;
opacity: 0;
}
/* check box */

/* The check */
.check {
display: block;
position: relative;
padding-left: 25px;
margin-bottom: 12px;
padding-right: 15px;
cursor: pointer;
font-size: 18px;
-webkit-user-select: none;
-moz-user-select: none;
-ms-user-select: none;
user-select: none;
}

/* Hide the browser's default checkbox */
.check input {
position: absolute;
opacity: 0;
cursor: pointer;
}

/* Create a custom checkbox */
.checkmark {
position: absolute;
top: 3px;
left: 0;
height: 18px;
width: 18px;
border-color: #a9a7a7;
border-style: solid;
border-width: 2px;
}

/* When the checkbox is checked, add a blue background */
.check input:checked ~ .checkmark {
border-color: #3da5f4;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
content: "";
position: absolute;
display: none;
}

/* Show the checkmark when checked */
.check input:checked ~ .checkmark:after {
display: block;
}

/* Style the checkmark/indicator */
.check .checkmark:after {
left: 5px;
top: 1px;
width: 5px;
height: 10px;
border: solid;
border-color: #3da5f4;
border-width: 0 3px 3px 0;
-webkit-transform: rotate(45deg);
-ms-transform: rotate(45deg);
transform: rotate(45deg);
}

.asset-url button {
background: #22243b;
color: #c3bdbd;
border: #ccc;
}

.card-body {
/* min-height: calc(100vh - 124px); */
}
#snackbar {
border-radius: 2px;
padding: 8px 12px;
height: 37px;
}
.switch-height{
min-height: 24px;
}
.progress-md{
height: 8px!important;
}
/* Unanswer*/
.time-indicator{
    line-height: 2.4;
    font-weight: 600;
    line-height: 2.4;
    font-weight: 600;
    opacity: 1;
    background-color: #2b7a77;
    border-radius: 18px;
    width: 90px;
    text-align: center;
    color: #feffff;
}

.badge.badge-pill{
    padding: .375rem .5625rem;

}
/* confirm modal */
.confirm-modal .modal-dialog{
    max-width: 300px;;
}
.confirm-modal .modal-dialog .modal-content .modal-header {
    padding: 10px 11px;
}
.confirm-modal .modal-dialog .modal-content .modal-body {
    padding: 15px 16px;
}
.confirm-modal .modal-dialog .modal-content .modal-footer {
    padding: 10px 11px;
}
.confirm-modal {
    max-width:400px!important;
    margin: 0 auto;;
}
.submit-btn {
    margin-top: 25px;
    display: block;
    float: right;
}

.answered-timer{
    background-color: #2b7a77;
    border-radius: 19px;
    overflow: hidden;
    max-width: 90px;
    padding: 8px 10px;
    text-align: center;
    line-height: 12px;
    font-size: 13px;
    font-weight: 700px;
    color: #ffffff;
    height: 26px;
    margin-top: 10px;
}

> li {
  font-size: 12px; }

.class_dashboard {
  position: fixed;
  left: 0px;
  width: 100vw;
  background-color: white;
  z-index: 101;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-weight: 300;
  overflow-y: scroll;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out; }
  .class_dashboard::-webkit-scrollbar {
    display: none; }
  .class_dashboard > div:nth-child(1) {
    height: 45px;
    background-color: #3F3D56;
    border-top: 1px solid rgba(245, 245, 245, 0.2);
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: row;
        flex-direction: row;
    -ms-flex-align: center;
        align-items: center;
    padding-left: 20px;
    padding-right: 20px;
    color: white; }
    .class_dashboard > div:nth-child(1) > label {
      margin-top: auto;
      margin-bottom: auto; }
    .class_dashboard > div:nth-child(1) > :nth-child(2) {
      margin-left: auto;
      margin-right: 20px; }
    .class_dashboard > div:nth-child(1) > :nth-child(3) {
      margin-right: 20px;
      font-size: 14px; }
    .class_dashboard > div:nth-child(1) > i {
      margin-top: auto;
      margin-bottom: auto;
      cursor: pointer; }
    .class_dashboard > div:nth-child(1) > .minus {
      margin-bottom: 18px;
      margin-right: 14px; }
    .class_dashboard > div:nth-child(1) > .maximize {
      margin-right: 14px; }
  .class_dashboard > :nth-child(2) {
    overflow: hidden;
    -webkit-transition: 0.3s all ease-in-out;
    transition: 0.3s all ease-in-out;
    box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: row;
        flex-direction: row; }
    .class_dashboard > :nth-child(2) > div:nth-child(1) {
      display: -ms-flexbox;
      display: flex;
      -ms-flex-direction: row;
          flex-direction: row; }
    .class_dashboard > :nth-child(2) > div {
      -ms-flex: 1 1;
          flex: 1 1; }
      .class_dashboard > :nth-child(2) > div > p {
        font-size: 14px;
        text-align: justify; }
      .class_dashboard > :nth-child(2) > div > div {
        -ms-flex: 1 1;
            flex: 1 1; }
      .class_dashboard > :nth-child(2) > div > div > label {
        font-size: 13.5px;
        font-weight: 500;
        display: block;
        margin-bottom: 10px; }
        .class_dashboard > :nth-child(2) > div > div > label > label {
          font-weight: 500;
          font-size: 13.5px;
          opacity: 0.7; }
  .class_dashboard > :nth-child(3) {
    height: 340px;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: row;
        flex-direction: row; }
    .class_dashboard > :nth-child(3) > :nth-child(1) {
      display: -ms-flexbox;
      display: flex;
      -ms-flex: 1 1;
          flex: 1 1;
      -ms-flex-direction: column;
          flex-direction: column;
      height: 315px;
      box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.3);
      background-color: white;
      border-radius: 10px;
      margin: 10px;
      padding: 10px; }
    .class_dashboard > :nth-child(3) > :nth-child(2) {
      -ms-flex: 1 1;
          flex: 1 1;
      height: 314px;
      background-color: white;
      box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.3);
      border-radius: 10px;
      margin-top: 10px; }
    .class_dashboard > :nth-child(3) > :nth-child(3) {
      -ms-flex: 1 1;
          flex: 1 1;
      height: 315px;
      background-color: white;
      box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.3);
      border-radius: 10px;
      margin-left: 10px;
      margin-top: 10px;
      padding: 10px; }
    .class_dashboard > :nth-child(3) > :nth-child(4) {
      -ms-flex: 1 1;
          flex: 1 1;
      height: 315px;
      background-color: white;
      box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.3);
      border-radius: 10px;
      margin-left: 10px;
      margin-right: 10px;
      margin-top: 10px;
      padding: 10px; }
  .class_dashboard > :nth-child(4) {
    height: 350px;
    box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    margin: 10px; }
  .class_dashboard > :nth-child(5) {
    height: 400px;
    box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    margin: 20px 10px; }
  .class_dashboard > :nth-child(6) {
    box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    margin: 20px 10px;
    padding: 5px; }
    .class_dashboard > :nth-child(6) > div {
      display: -ms-flexbox;
      display: flex;
      margin: 10px; }
      .class_dashboard > :nth-child(6) > div > label {
        display: -ms-flexbox;
        display: flex; }
      .class_dashboard > :nth-child(6) > div > div {
        margin-left: auto;
        font-size: 14px;
        font-weight: 300;
        cursor: pointer;
        color: #009CCE;
        padding-bottom: 2px; }
        .class_dashboard > :nth-child(6) > div > div > input {
          margin-top: 8px;
          margin-right: 8px; }
  .class_dashboard > :nth-child(7) {
    box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    margin: 20px 10px;
    padding: 5px; }
    .class_dashboard > :nth-child(7) > div {
      display: -ms-flexbox;
      display: flex;
      margin: 10px; }
      .class_dashboard > :nth-child(7) > div > label {
        display: -ms-flexbox;
        display: flex; }
      .class_dashboard > :nth-child(7) > div > div {
        margin-left: auto;
        font-size: 14px;
        font-weight: 300;
        cursor: pointer;
        color: #009CCE;
        padding-bottom: 2px; }
        .class_dashboard > :nth-child(7) > div > div > input {
          margin-top: 8px;
          margin-right: 8px; }

.show_report_card {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: start;
      align-items: flex-start;
  padding-top: 20px;
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  z-index: 9999;
  background-color: rgba(0, 0, 0, 0.1);
  -webkit-backdrop-filter: blur(3px);
          backdrop-filter: blur(3px); }
  .show_report_card > div {
    margin-top: 40px;
    width: 900px;
    background-color: white;
    box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.3);
    border-radius: 2px; }
    .show_report_card > div > div:nth-child(1) {
      display: -ms-flexbox;
      display: flex;
      background-color: #1b1b38;
      color: white;
      height: 45px;
      -ms-flex-align: center;
          align-items: center;
      padding-left: 10px;
      padding-right: 10px; }
      .show_report_card > div > div:nth-child(1) > :nth-child(2) {
        margin-left: auto;
        margin-right: 24px; }
      .show_report_card > div > div:nth-child(1) > :nth-child(3) {
        margin-left: auto;
        cursor: pointer;
        opacity: 0.6;
        font-size: 14px; }
    .show_report_card > div > div:nth-child(2) {
      display: -ms-flexbox;
      display: flex;
      -ms-flex-direction: row;
          flex-direction: row;
      margin-bottom: 8px;
      margin-top: 10px;
      padding: 5px 30px; }
      .show_report_card > div > div:nth-child(2) > p {
        margin-bottom: 0px;
        margin-right: 20px; }
      .show_report_card > div > div:nth-child(2) > div {
        margin-left: auto;
        font-size: 14px; }
    .show_report_card > div > div:nth-child(3) {
      padding: 5px 30px; }
      .show_report_card > div > div:nth-child(3) > table {
        width: 100%; }
        .show_report_card > div > div:nth-child(3) > table > thead > tr {
          border-bottom: 1px solid rgba(0, 0, 0, 0.1);
          padding-bottom: 6px; }
          .show_report_card > div > div:nth-child(3) > table > thead > tr > th {
            text-align: center; }
          .show_report_card > div > div:nth-child(3) > table > thead > tr > :nth-child(1) {
            padding-right: 20px; }
        .show_report_card > div > div:nth-child(3) > table > tbody > tr {
          height: 40px; }

.show_game_report_card {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: start;
      align-items: flex-start;
  padding-top: 20px;
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  z-index: 999999;
  background-color: rgba(0, 0, 0, 0.1);
  -webkit-backdrop-filter: blur(3px);
          backdrop-filter: blur(3px); }
  .show_game_report_card > div {
    margin-top: 40px;
    box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.3);
    border-radius: 2px;
    background-color: white; }
    .show_game_report_card > div > div:nth-child(1) {
      display: -ms-flexbox;
      display: flex;
      background-color: #1b1b38;
      color: white;
      height: 45px;
      -ms-flex-align: center;
          align-items: center;
      padding-left: 10px;
      padding-right: 10px; }
      .show_game_report_card > div > div:nth-child(1) > :nth-child(2) {
        margin-left: auto;
        margin-right: 24px; }
      .show_game_report_card > div > div:nth-child(1) > :nth-child(3) {
        margin-left: auto;
        cursor: pointer;
        opacity: 0.6;
        font-size: 14px; }
    .show_game_report_card > div > div:nth-child(2) {
      display: -ms-flexbox;
      display: flex;
      -ms-flex-direction: row;
          flex-direction: row;
      padding: 5px 30px;
      margin-top: 10px; }
      .show_game_report_card > div > div:nth-child(2) > p {
        margin-bottom: 0px;
        margin-right: 20px; }
      .show_game_report_card > div > div:nth-child(2) > div {
        margin-left: auto;
        font-size: 14px; }
    .show_game_report_card > div > div:nth-child(3) {
      padding: 5px 30px; }
      .show_game_report_card > div > div:nth-child(3) > table {
        width: 100%; }
        .show_game_report_card > div > div:nth-child(3) > table > thead > tr {
          border-bottom: 1px solid rgba(0, 0, 0, 0.1);
          padding-bottom: 6px; }
          .show_game_report_card > div > div:nth-child(3) > table > thead > tr > th {
            text-align: center;
            padding-right: 20px; }
          .show_game_report_card > div > div:nth-child(3) > table > thead > tr > :nth-child(1) {
            padding-right: 20px; }
        .show_game_report_card > div > div:nth-child(3) > table > tbody > tr {
          height: 40px; }
          .show_game_report_card > div > div:nth-child(3) > table > tbody > tr > td {
            padding-right: 20px; }

.contest_dashboard {
  position: fixed;
  left: 0px;
  width: 100vw;
  background-color: white;
  z-index: 101;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-weight: 300;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  overflow-y: scroll; }
  .contest_dashboard::-webkit-scrollbar {
    display: none; }
  .contest_dashboard > div:nth-child(1) {
    height: 45px;
    background-color: #3F3D56;
    border-top: 1px solid rgba(245, 245, 245, 0.2);
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: row;
        flex-direction: row;
    padding-left: 20px;
    padding-right: 20px;
    color: white; }
    .contest_dashboard > div:nth-child(1) > label {
      margin-top: auto;
      margin-bottom: auto; }
    .contest_dashboard > div:nth-child(1) > :nth-child(2) {
      margin-left: auto;
      margin-right: 20px; }
    .contest_dashboard > div:nth-child(1) > :nth-child(3) {
      margin-right: 20px;
      font-size: 14px; }
    .contest_dashboard > div:nth-child(1) > i {
      margin-top: auto;
      margin-bottom: auto;
      cursor: pointer; }
    .contest_dashboard > div:nth-child(1) > .minus {
      margin-bottom: 18px;
      margin-right: 14px; }
    .contest_dashboard > div:nth-child(1) > .maximize {
      margin-right: 14px; }
  .contest_dashboard > :nth-child(2) {
    overflow: hidden;
    -webkit-transition: 0.3s all ease-in-out;
    transition: 0.3s all ease-in-out;
    box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: row;
        flex-direction: row; }
    .contest_dashboard > :nth-child(2) > div:nth-child(1) {
      display: -ms-flexbox;
      display: flex;
      -ms-flex-direction: row;
          flex-direction: row; }
    .contest_dashboard > :nth-child(2) > div {
      -ms-flex: 1 1;
          flex: 1 1; }
      .contest_dashboard > :nth-child(2) > div > p {
        font-size: 14px;
        text-align: justify; }
      .contest_dashboard > :nth-child(2) > div > div {
        -ms-flex: 1 1;
            flex: 1 1; }
      .contest_dashboard > :nth-child(2) > div > div > label {
        font-size: 13.5px;
        font-weight: 500;
        display: block;
        margin-bottom: 10px; }
        .contest_dashboard > :nth-child(2) > div > div > label > label {
          font-weight: 500;
          font-size: 13.5px;
          opacity: 0.7; }
  .contest_dashboard > :nth-child(3) {
    height: 340px;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: row;
        flex-direction: row; }
    .contest_dashboard > :nth-child(3) > :nth-child(1) {
      display: -ms-flexbox;
      display: flex;
      -ms-flex-direction: column;
          flex-direction: column; }
      .contest_dashboard > :nth-child(3) > :nth-child(1) > :nth-child(1) {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-direction: row;
            flex-direction: row;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
        width: 400px; }
        .contest_dashboard > :nth-child(3) > :nth-child(1) > :nth-child(1) > div {
          height: 95px;
          width: 175px;
          box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.3);
          background-color: white;
          display: -ms-flexbox;
          display: flex;
          -ms-flex-direction: column;
              flex-direction: column;
          -ms-flex-pack: center;
              justify-content: center;
          -ms-flex-align: center;
              align-items: center;
          border-radius: 10px;
          position: relative;
          margin: 10px; }
          .contest_dashboard > :nth-child(3) > :nth-child(1) > :nth-child(1) > div > img {
            width: 120px;
            height: 60px;
            opacity: 0.2; }
          .contest_dashboard > :nth-child(3) > :nth-child(1) > :nth-child(1) > div > div {
            position: absolute;
            height: 85px;
            width: 155px;
            display: -ms-flexbox;
            display: flex;
            -ms-flex-direction: column;
                flex-direction: column;
            -ms-flex-align: center;
                align-items: center; }
            .contest_dashboard > :nth-child(3) > :nth-child(1) > :nth-child(1) > div > div > label {
              margin-top: auto;
              font-size: 14px; }
            .contest_dashboard > :nth-child(3) > :nth-child(1) > :nth-child(1) > div > div > p {
              font-size: 17px;
              margin-bottom: auto; }
      .contest_dashboard > :nth-child(3) > :nth-child(1) > :nth-child(2) {
        width: 370px;
        height: 200px;
        background-color: white;
        box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.3);
        border-radius: 10px;
        margin-left: 10px;
        margin-top: 10px; }
    .contest_dashboard > :nth-child(3) > :nth-child(2) {
      -ms-flex: 1 1;
          flex: 1 1;
      height: 340px;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-direction: row;
          flex-direction: row; }
      .contest_dashboard > :nth-child(3) > :nth-child(2) > :nth-child(1) {
        height: 315px;
        background-color: white;
        box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.3);
        border-radius: 10px;
        margin-left: 10px;
        margin-top: 10px;
        padding: 10px; }
      .contest_dashboard > :nth-child(3) > :nth-child(2) > :nth-child(2) {
        -ms-flex: 1 1;
            flex: 1 1;
        height: 315px;
        background-color: white;
        box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.3);
        border-radius: 10px;
        margin-left: 10px;
        margin-top: 10px;
        padding: 10px; }
  .contest_dashboard > :nth-child(4) {
    height: 390px;
    box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    margin: 10px; }
  .contest_dashboard > :nth-child(5) {
    box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    margin: 20px 10px;
    padding: 5px; }
    .contest_dashboard > :nth-child(5) > div {
      display: -ms-flexbox;
      display: flex;
      margin: 10px; }
      .contest_dashboard > :nth-child(5) > div > label {
        display: -ms-flexbox;
        display: flex; }
      .contest_dashboard > :nth-child(5) > div > div {
        margin-left: auto;
        font-size: 14px;
        font-weight: 300;
        cursor: pointer;
        color: #009CCE;
        padding-bottom: 2px; }
        .contest_dashboard > :nth-child(5) > div > div > input {
          margin-top: 8px;
          margin-right: 8px; }

/* quiz section */
.mt-25 {
  margin-top: 25px;
}
.top-question {
  display: -webkit-box;
}
.q-no {
  width: 50px;
  height: 50px;
  background-color: #1a73e8;
  display: block;
  color: #fff;
  margin-right: 10px;
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  line-height: 50px;
  border-radius: 5px 5px 5px 5px;
}
.question-wrap {
  font-size: 16px;
  font-weight: bold;
  /* margin-left: -10px; */
}
.question-wrap p {
  color: #828282;
  margin-left: -3%;
  margin-top: 0.9%;
}
.answer-wrap {
  display: -ms-flexbox;
  display: flex;
  background-color: #f5f5f5;
  width: 45%;
  cursor: pointer;
  padding: 10px;
  margin-left: 2%;
  /* -webkit-border-radius: 50px 50px 50px 50px;
  border-radius: 50px 50px 50px 50px; */
  border-radius: 4px;
  box-shadow: 0 1px 3px 3px #dedede;
  -ms-flex-align: center;
      align-items: center;
  /* -webkit-transition: .5s ease-in-out;
    -moz-transition: .5s ease-in-out;
    -o-transition: .5s ease-in-out;
    transition: .5s ease-in-out; */
  margin: 20px;
}
.answer-wrap:hover {
  background-color: #1a73e8;
}
.answer-wrap:hover .option-number {
  background-color: #fff;
  color: #1a73e8;
}
.answer-wrap:hover .option-text {
  color: #fff;
}
.option-number {
  width: 40px;
  height: 40px;
  background-color: #1a73e8;
  display: table;
  color: #fff;
  text-align: center;
  line-break: 12px;
  font-size: 16px;
  line-height: 40px;
  /* border-radius: 50%; */
  border-radius: 4px;
  /* -webkit-transition: .5s ease-in-out;
     -moz-transition: .5s ease-in-out;
     -o-transition: .5s ease-in-out;
     transition: .5s ease-in-out; */
}
.option-text {
  padding-left: 15px;
  display: table;
  font-size: 14px;
  font-weight: bold;
  color: #828282;
  word-break: break-all;
  /* margin-top: 5px; */
  /* -webkit-transition: .5s ease-in-out;
    -moz-transition: .5s ease-in-out;
    -o-transition: .5s ease-in-out;
    transition: .5s ease-in-out; */
}
.option-text p {
  font-weight: bold;
  color: #828282;
  vertical-align: middle;
}
.quiz-button-pos {
  margin-left: 44%;
}
.quiz-button {
  background-color: #1a73e8;
  color: white;
  font-size: 20px;
  font-weight: bold;
  width: 22%;
  border-radius: 15px 15px 15px 15px;
}

.btn-wrapper {
  float: right;
  margin-top: 10px;
}
.option-row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
      flex-direction: row;
}
.select-option {
  display: -ms-flexbox;
  display: flex;
  width: 45%;
  background-color: #1a73e8;
  color: white;
  cursor: pointer;
  padding: 10px;
  /* margin-left: 2%; */
  border-radius: 4px;
  /* -webkit-border-radius: 50px 50px 50px 50px;
  border-radius: 50px 50px 50px 50px; */
  box-shadow: 0 1px 3px 3px #dedede;
  -ms-flex-align: center;
      align-items: center;
  margin: 20px;
  /* margin-top: 1.5%; */
}
.select-text {
  padding-left: 15px;
  display: table;
  font-size: 14px;
  font-weight: bold;
  color: white;
  word-break: break-all;
  margin-top: 0 !important;
  /* margin-top: 5px; */
  /* -webkit-transition: .5s ease-in-out;
  -moz-transition: .5s ease-in-out;
  -o-transition: .5s ease-in-out;
  transition: .5s ease-in-out; */
}

.select-number {
  width: 40px;
  height: 40px;
  background-color: #fff;
  display: table;
  color: #1a73e8;
  text-align: center;
  line-break: 12px;
  font-size: 18px;
  line-height: 40px;
  border-radius: 4px;
  /* -webkit-border-radius: 5px 0 0 5px;
  border-radius: 50%; */
  /* margin-top: 1.5%; */
  /* -webkit-transition: .5s ease-in-out;
    -moz-transition: .5s ease-in-out;
    -o-transition: .5s ease-in-out;
    transition: .5s ease-in-out; */
}
.btn-pagination {
  background-color: #1a73e8;
  color: #fff;
}
.prev-btn {
  margin-right: 12px;
}
.btn-disable {
  cursor: not-allowed;
}
.quiz-card {
  padding-bottom: 150px;
  border-radius: 4px;
  padding-top: 20px;
  padding-left: 30px !important;
  padding-right: 30px !important;
}
.q-img-section {
  height: 250px;
  background: #f9f9f9;
  border-radius: 10px;
  text-align: center;
}
.q-img-section img {
  height: 100%;
  object-fit: contain;
}

.answer-text {
  border-radius: 10px;
}
.quiz-title {
  font-weight: bold;
  color: #1a73e8;
  text-align: center;
  padding: 15px 0;
  font-size: 30px;
}
.image-question {
  transition: -webkit-transform 0.25s ease;
  -webkit-transition: -webkit-transform 0.25s ease;
  transition: transform 0.25s ease;
  transition: transform 0.25s ease, -webkit-transform 0.25s ease;
  margin-top: 20px;
}

.image-question:active {
  -webkit-transform: scale(2);
  -ms-transform: scale(2);
      transform: scale(2);
}
/* quiz section end here */

.elapse-timer {
  padding: 5px;
  background-color: #1a73e8;
  color: #ffffff;
  float: right;
  width: 80px;
  /* border-radius: 10px; */
  border-radius: 4px;
}

.quiz_view {
  position: fixed;
  left: 45px;
  bottom: 80px;
  right: 0px;
  top: 45px;
  left: 0px;
  width: 100vw;
  background-color: white;
  z-index: 350;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  overflow-y: scroll; }
  .quiz_view > .quiz_header {
    min-height: 45px;
    background-color: #3F3D56;
    border-top: 1px solid rgba(245, 245, 245, 0.2);
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: row;
        flex-direction: row;
    -ms-flex-align: center;
        align-items: center;
    padding-left: 20px;
    padding-right: 20px;
    color: white; }
    .quiz_view > .quiz_header > label {
      margin-top: auto;
      margin-bottom: auto; }
    .quiz_view > .quiz_header > :nth-child(2) {
      margin-left: auto;
      margin-right: 20px; }
    .quiz_view > .quiz_header > :nth-child(3) {
      margin-right: 20px;
      font-size: 14px; }
    .quiz_view > .quiz_header > i {
      margin-top: auto;
      margin-bottom: auto;
      cursor: pointer; }
    .quiz_view > .quiz_header > .minus {
      margin-bottom: 18px;
      margin-right: 14px; }
    .quiz_view > .quiz_header > .maximize {
      margin-right: 14px; }

.quiz-result {
  border: 1px solid #e7680c;
  border-radius: 45px 45px 45px 45px;
  padding: 15px;
  background-color: #fff8f3;
}
.quiz-title {
  font-weight: bold;
  color: #1a73e8;
  text-align: center;
  padding: 15px 0;
  font-size: 30px;
}
.result-header {
  font-weight: bold;
  padding: 15px 0;
  font-size: 30px;
  color: grey;
}

.data-result {
  padding: 10px 24px;
  background-color: #e0cbbd;
  color: rgb(36, 33, 33);
}
.data-result span {
  font-size: 19px;
  text-align: center;
}
.result-pass {
  color: green;
}
.result-fail {
  color: rgb(221, 27, 27);
}
.verdict-text {
  font-size: 30px;
  color: #1a73e8;
  text-align: center;
}
.header-style {
  background-color: blue;
}
.report-card-title {
  background-color: #1a73e8;
  padding: 10px 10px 10px 19px;
  color: #fff;
  font-weight: bold;
  font-size: 21px;
  margin-top: 50px;
}
.result-container {
  margin: 10px;
  width: 70%;
  position: absolute;
  z-index: 15;
  top: 20%;
  left: 20%;
}
.report-card-fail {
  text-align: center;
  font-size: 20px;
  color: rgb(245, 3, 3);
  font-family: "Roboto", sans-serif;
}
.report-card-pass {
  text-align: center;
  font-size: 22px;
  color: rgb(35, 121, 32);
  font-family: "Roboto", sans-serif;
}
.result-box {
  background-color: #e0cbbd;
}

.topic-block {
  margin-top: 70px;
  padding: 10px;
  box-shadow: 0 0 15px 5px #ebebeb;
  border-radius: 15px;
  border: 2px solid #1a73e8;
}
.topic-head {
  background-color: #1a73e8;
  padding: 8px 8px 8px 20px;
  color: #fff;
  font-size: 20px;
  margin-top: 40px;
  font-weight: 500;
}
.link-list li {
  width: 100%;
  height: 57px;
  background-color: #fdfdfd;
  border-bottom: 1px solid #e2dfdf;
}
.link-list li a {
  width: 100%;
  color: #5f5f5f;
  font-size: 16px;
  font-weight: 500;
  line-height: 25px;
  padding: 16px;
  display: block;
  height: 57px;
}
.link-list li:last-child {
  width: 100%;
  border-bottom: transparent;
}
.right-arrow {
  float: right;
  margin-top: 3px;
}
.btn-topic {
  cursor: pointer;
}
.topic-title {
  text-align: center;
  color: #1a73e8;
  font-weight: bold;
  text-decoration: underline;
}
.topic-progress-group {
  width: 100%;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
      flex-direction: row;
}
.tab {
  overflow: auto;
  border: 1px solid #ccc;
  background-color: #f1f1f1;
  width: 100%;
}

.tablinks {
  width: 50%;
  background-color: inherit;
  float: left;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 8px 8px;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}
.tablinks-active {
  width: 50%;
  background-color: #ffff;
  color: #ccc;
  float: left;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 8px 8px;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  border-bottom: 4px solid #1a73e8;
}
.expand-collapse {
  vertical-align: middle;
  margin-top: 14px;
}
.expand-item {
  width: 100%;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
      flex-direction: row;
}
.expand-start {
  width: 50%;
  text-align: center;
  font-size: 16px;
}
.expand-end {
  width: 50%;
  text-align: center;
  font-size: 16px;
}

.tab {
  overflow: auto;
  background-color: #f1f1f1;
  font-size: 16px;
  color: #999;
}

.tablinks-active {
  color: #333;
  background-color: #f3f3f3;
}

.list-group-item {
  position: relative;
  display: block;
  padding: 0px 0px;
}

.list-group {
  padding-left: 0;
  margin-bottom: 20px;
  padding-right: 0;
}

.list-item {
  font-size: 18px;
  margin-left: 10px;
  list-style-type: disc;
  line-height: 2.6;
  color: black;
}
.sublist-item {
  font-size: 16px;
  margin-left: 50px;
  list-style-type: disc;
  line-height: 1.9;
  color: black;
}
.sub-sublist-item {
  font-size: 14px;
  margin-left: 90px;
  list-style-type: disc;
  line-height: 1.9;
  color: black;
}
.topic-heading {
  /* text-decoration: underline; */
  font-weight: bold;
  margin-bottom: 10px;
}
.topic-name {
  text-align: center;
  font-weight: bold;
  color: #1a73e8;
  font-size: 30px;
  margin-bottom: 20px;
  display: none;
}
.bottom-margin {
  padding-bottom: 100px;
}
.page-number {
  font-weight: 700;
  position: "absolute";
  float: right;
  margin-top: 35px;
  margin-right: 10px;
}

.curriculum-page {
  padding-top: 20px !important;
  padding-left: 30px !important;
  padding-right: 30px !important;
}

.topic-heading {
  text-align: center;
  font-size: 24px;
}
/* .list-item {
  font-weight: 500;
} */

.topicitem_root {
  position: fixed;
  left: 0px;
  right: 0px;
  z-index: 250;
  background-color: transparent;
  overflow: hidden; }

.topic_stocks_trader {
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.3);
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  position: fixed;
  left: 0px;
  right: 0px;
  width: 100vw;
  z-index: 350;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  background-color: white;
  overflow: hidden; }
  .topic_stocks_trader > div:nth-child(1) {
    min-height: 45px;
    background-color: #3F3D56;
    border-top: 1px solid rgba(245, 245, 245, 0.2);
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: row;
        flex-direction: row;
    -ms-flex-align: center;
        align-items: center;
    padding-left: 20px;
    padding-right: 20px;
    color: white; }
    .topic_stocks_trader > div:nth-child(1) > label {
      margin-top: auto;
      margin-bottom: auto; }
    .topic_stocks_trader > div:nth-child(1) > :nth-child(2) {
      margin-left: auto;
      margin-right: 20px; }
    .topic_stocks_trader > div:nth-child(1) > :nth-child(3) {
      margin-right: 20px;
      font-size: 14px; }
    .topic_stocks_trader > div:nth-child(1) > i {
      margin-top: auto;
      margin-bottom: auto;
      cursor: pointer; }
    .topic_stocks_trader > div:nth-child(1) > .minus {
      margin-bottom: 18px;
      margin-right: 14px; }
    .topic_stocks_trader > div:nth-child(1) > .maximize {
      margin-right: 14px; }
  .topic_stocks_trader > div:nth-child(2) {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: row;
        flex-direction: row;
    -ms-flex: 1 1;
        flex: 1 1;
    margin-top: 10px;
    overflow-y: scroll; }
    .topic_stocks_trader > div:nth-child(2) > :nth-child(1) {
      display: -ms-flexbox;
      display: flex;
      -ms-flex-direction: column;
          flex-direction: column;
      border-right: 0.3px solid rgba(0, 0, 0, 0.3); }
      .topic_stocks_trader > div:nth-child(2) > :nth-child(1) > :nth-child(1) {
        padding-top: 10px;
        padding-left: 20px;
        color: #1A73E8; }
      .topic_stocks_trader > div:nth-child(2) > :nth-child(1) p {
        font-size: 13px;
        color: #1A73E8;
        padding-left: 20px;
        padding-top: 10px; }
      .topic_stocks_trader > div:nth-child(2) > :nth-child(1) > :nth-child(3) {
        width: 240px;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-direction: column;
            flex-direction: column;
        -ms-flex: 1 1;
            flex: 1 1; }
        .topic_stocks_trader > div:nth-child(2) > :nth-child(1) > :nth-child(3) > li {
          padding-top: 4px;
          padding-bottom: 4px;
          padding-left: 40px;
          padding-right: 10px;
          font-size: 13px;
          cursor: pointer;
          border-bottom: 1px solid rgba(0, 0, 0, 0.1);
          color: "black";
          font-weight: bold; }
        .topic_stocks_trader > div:nth-child(2) > :nth-child(1) > :nth-child(3) > .start_quiz_button {
          background-color: #1A73E8;
          color: white;
          box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.3);
          font-size: 14px;
          line-height: 25px;
          padding: 0px;
          margin-top: auto;
          text-align: center;
          padding-top: 8px; }
    .topic_stocks_trader > div:nth-child(2) > :nth-child(2) {
      -ms-flex: 1 1;
          flex: 1 1;
      padding: 20px 40px;
      overflow-y: scroll; }

/* .completed-bar {
    background-color: #1ba841;
    height: 25px;
    width: 100px;
    margin-top: 15px;
    text-align: center;
    color:#fff;
    font-weight:500;
} */
.completed-bar {
  background-color: #1ba841;

  width: 120px;

  margin-top: 12px;

  text-align: center;

  color: #fff;

  font-weight: 500;

  margin-bottom: 12px;
}
/* .inprogress-bar {
  background-color: #ff8400;
  height: 25px;
  width: 100px;
  margin-top: 15px;
  text-align: center;
  color: #fff;
  font-weight: 500;
} */
.inprogress-bar {
  background-color: #ff8400;
  width: 120px;

  margin-top: 12px;

  text-align: center;

  color: #fff;

  font-weight: 500;

  margin-bottom: 12px;
}
/* .pending-bar {
  background-color: #5e77a5;
  height: 25px;
  width: 100px;
  margin-top: 15px;
  text-align: center;
  color: #fff;
  font-weight: 500;
} */
.pending-bar {
  background-color: #5e77a5;
  width: 120px;

  margin-top: 12px;

  text-align: center;

  color: #fff;

  font-weight: 500;

  margin-bottom: 12px;
}
/* .ready-bar {
  background-color: #23e000;
  height: 25px;
  width: 100px;
  margin-top: 15px;
  text-align: center;
  color: #fff;
  font-weight: 500;
} */
.ready-bar {
  background-color: #23e000;
  width: 120px;

  margin-top: 12px;

  text-align: center;

  color: #fff;

  font-weight: 500;

  margin-bottom: 12px;
}

.class-cards {
  background-color: #fff;
  margin: auto;
  width: 90%;
  height: 184px;
  position: relative;
  box-shadow: 0 0 15px 5px #ebebeb;
  margin-bottom: 40px;
  border-radius: 15px;
  border: 2px solid #1a73e8;
}
.class-container {
  /* height:60vh; */
}
.ic-del {
  position: absolute;
  font-size: 10px;
  right: -16px;
  top: -8px;
  cursor: pointer;
  margin: 15px;
  padding: 8px;
  margin-right: 23px;
  margin-top: 15px;
  background-color: #1a73e8 !important;
  border-radius: 5px;
  color: #ffffff;
}

.class-name-wrap {
  position: relative;
  padding: 10px;
  top: 33%;
  -webkit-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
          transform: translateY(-50%);
  text-align: left;
  cursor: pointer;
  height: 140px;
  font-family: "Times New Roman", Times, serif;
}

.class-name-wrap h4 {
  color: #afaaaa;
  font-size: 20px;
}
.add-new-btn {
  float: right;
  margin: 25px 45px 25px;
  background-color: #1a73e8 !important;
}

.class-datas {
  color: #afaaaa;
  margin: 0;
  position: absolute;
  width: 90%;
  padding-bottom: 6px;
  font-size: 15px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  text-align: left;
}
.item-data {
  font-weight: bold;
  color: black;
}
.class-btn {
  position: absolute;
  left: 0px;
}
.year-filter {
  width: 160px;
  position: absolute;
  left: 500px;
  top: 80px;
}

.card-footer {
  padding: 0.4rem 0.75rem;
  background-color: #1a73e8;
  border-top: 1px solid rgba(0, 0, 0, 0.125);
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
}

.text-muted {
  color: #f5f5f5;
}

.card-body:hover {
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
  background-color: #f5f5f5;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
}

.add-new-class {
  -ms-flex-align: center;
      align-items: center;
  border-radius: 8px;
  box-sizing: border-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  -ms-flex-positive: 1;
      flex-grow: 1;
  -ms-flex-pack: center;
      justify-content: center;
}

#collaborate {
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  z-index: 99999;
  background-color: white;
  box-sizing: border-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column; }
  #collaborate #header {
    background-color: #00ACDF;
    height: 50px;
    display: -ms-flexbox;
    display: flex;
    padding: 0px 20px;
    -ms-flex-align: center;
        align-items: center;
    -ms-flex-direction: row;
        flex-direction: row;
    color: white; }
  #collaborate #header1 {
    background-color: #e4e4e4;
    height: 35px;
    display: -ms-flexbox;
    display: flex;
    padding: 0px 20px;
    -ms-flex-align: center;
        align-items: center;
    -ms-flex-direction: row;
        flex-direction: row;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2); }
  #collaborate #container {
    -ms-flex: 1 1;
        flex: 1 1;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: row;
        flex-direction: row; }
    #collaborate #container #aside {
      width: 170px;
      background-color: #e4e4e4; }
    #collaborate #container #aside1 {
      width: 350px;
      overflow-y: scroll;
      border-right: 1px solid rgba(0, 0, 0, 0.2); }

* {
  scroll-behavior: smooth; }

._help {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100vh;
  z-index: 150;
  background-color: white;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande", "Lucida Sans Unicode", Geneva, Verdana, sans-serif; }
  ._help > :nth-child(1) {
    position: -webkit-sticky;
    position: sticky;
    height: 50px;
    background-color: #3F3D56;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: row;
        flex-direction: row;
    -ms-flex-align: center;
        align-items: center;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 6px; }
    ._help > :nth-child(1) > :nth-child(1) {
      margin-bottom: 4px; }
    ._help > :nth-child(1) > h3 {
      color: white;
      font-weight: 400; }
    ._help > :nth-child(1) > label {
      font-weight: 100;
      color: white; }
    ._help > :nth-child(1) > :nth-child(2) {
      font-size: 20px;
      opacity: 0.9;
      margin-top: auto;
      margin-left: 10px; }
    ._help > :nth-child(1) > :nth-child(3) {
      margin-left: auto; }
    ._help > :nth-child(1) > a {
      margin-right: 20px;
      font-size: 14px;
      opacity: 0.8;
      -webkit-transition: all 0.1s ease-in-out;
      transition: all 0.1s ease-in-out;
      color: white;
      margin-bottom: 5px; }
      ._help > :nth-child(1) > a:hover {
        opacity: 1;
        cursor: pointer;
        font-size: 16px; }
  ._help > :nth-child(2) {
    -ms-flex: 1 1;
        flex: 1 1;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: row;
        flex-direction: row;
    overflow-y: scroll; }
    ._help > :nth-child(2)::-webkit-scrollbar {
      display: none; }
    ._help > :nth-child(2) > :nth-child(1) {
      display: -ms-flexbox;
      display: flex;
      -ms-flex-direction: column;
          flex-direction: column;
      -ms-flex: 1 1;
          flex: 1 1;
      padding: 10px 20px;
      overflow-y: scroll; }
      ._help > :nth-child(2) > :nth-child(1)::-webkit-scrollbar {
        display: none; }
      ._help > :nth-child(2) > :nth-child(1) .guide_li {
        display: block;
        list-style-type: disc;
        padding-bottom: 20px; }
        ._help > :nth-child(2) > :nth-child(1) .guide_li > li {
          font-size: 14px;
          margin-left: 10px;
          list-style-type: circle;
          margin-left: 40px; }
    ._help > :nth-child(2) > :nth-child(2) {
      width: 280px;
      border-left: 1px solid rgba(0, 0, 0, 0.1);
      padding-top: 20px; }
      ._help > :nth-child(2) > :nth-child(2) > ul > li {
        font-size: 14px;
        margin-left: 30px;
        line-height: 30px; }
        ._help > :nth-child(2) > :nth-child(2) > ul > li:hover {
          text-decoration: underline; }
      ._help > :nth-child(2) > :nth-child(2) > ul > ul > li {
        font-size: 13px;
        margin-left: 40px;
        line-height: 35px; }
        ._help > :nth-child(2) > :nth-child(2) > ul > ul > li:hover {
          text-decoration: underline; }

.title {
  font-size: 16px; }

p {
  font-size: 14px; }

li {
  font-size: 14px; }

.fm {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
      flex-direction: row;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  width: 370px;
  height: 300px;
  margin: 20px;
  margin-left: auto;
  margin-right: auto; }

.arch_text1 {
  font-size: 27px;
  font-weight: 900; }

.active_session {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100vh;
  -webkit-backdrop-filter: blur(6px);
          backdrop-filter: blur(6px);
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
  z-index: 150; }
  .active_session > :nth-child(1) {
    height: 150px;
    background-color: white;
    border-radius: 20px;
    box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.3);
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
        flex-direction: column;
    padding: 20px 30px; }
    .active_session > :nth-child(1) > :nth-child(1) {
      -ms-flex: 1 1;
          flex: 1 1;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-align: center;
          align-items: center; }
      .active_session > :nth-child(1) > :nth-child(1) > label {
        font-size: 15px;
        opacity: 0.9; }
    .active_session > :nth-child(1) > :nth-child(2) {
      display: -ms-flexbox;
      display: flex;
      -ms-flex-pack: end;
          justify-content: flex-end; }
      .active_session > :nth-child(1) > :nth-child(2) > label {
        font-size: 13px;
        padding: 4px 20px;
        border: 1px solid rgba(0, 0, 0, 0.3);
        border-radius: 10px;
        cursor: pointer; }
        .active_session > :nth-child(1) > :nth-child(2) > label:hover {
          opacity: 0.5; }

.idleTimeout {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100vh;
  -webkit-backdrop-filter: blur(6px);
          backdrop-filter: blur(6px);
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
  z-index: 150; }
  .idleTimeout > :nth-child(1) {
    height: 150px;
    background-color: white;
    border-radius: 20px;
    box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.3);
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
        flex-direction: column;
    padding: 20px 30px; }
    .idleTimeout > :nth-child(1) > :nth-child(1) {
      -ms-flex: 1 1;
          flex: 1 1;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-align: center;
          align-items: center; }
      .idleTimeout > :nth-child(1) > :nth-child(1) > label {
        font-size: 15px;
        opacity: 0.9; }
    .idleTimeout > :nth-child(1) > :nth-child(2) {
      display: -ms-flexbox;
      display: flex;
      -ms-flex-pack: end;
          justify-content: flex-end; }
      .idleTimeout > :nth-child(1) > :nth-child(2) > label {
        font-size: 13px;
        padding: 4px 20px;
        border: 1px solid rgba(0, 0, 0, 0.3);
        border-radius: 10px;
        cursor: pointer; }
        .idleTimeout > :nth-child(1) > :nth-child(2) > label:hover {
          opacity: 0.5; }

