.class-cards {
  background-color: #fff;
  margin: auto;
  width: 90%;
  height: 184px;
  position: relative;
  -webkit-box-shadow: 0 0 15px 5px #ebebeb;
  box-shadow: 0 0 15px 5px #ebebeb;
  margin-bottom: 40px;
  border-radius: 15px;
  border: 2px solid #1a73e8;
}
.class-container {
  /* height:60vh; */
}
.ic-del {
  position: absolute;
  font-size: 10px;
  right: -16px;
  top: -8px;
  cursor: pointer;
  margin: 15px;
  padding: 8px;
  margin-right: 23px;
  margin-top: 15px;
  background-color: #1a73e8 !important;
  border-radius: 5px;
  color: #ffffff;
}

.class-name-wrap {
  position: relative;
  padding: 10px;
  top: 33%;
  transform: translateY(-50%);
  text-align: left;
  cursor: pointer;
  height: 140px;
  font-family: "Times New Roman", Times, serif;
}

.class-name-wrap h4 {
  color: #afaaaa;
  font-size: 20px;
}
.add-new-btn {
  float: right;
  margin: 25px 45px 25px;
  background-color: #1a73e8 !important;
}

.class-datas {
  color: #afaaaa;
  margin: 0;
  position: absolute;
  width: 90%;
  padding-bottom: 6px;
  font-size: 15px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  text-align: left;
}
.item-data {
  font-weight: bold;
  color: black;
}
.class-btn {
  position: absolute;
  left: 0px;
}
.year-filter {
  width: 160px;
  position: absolute;
  left: 500px;
  top: 80px;
}

.card-footer {
  padding: 0.4rem 0.75rem;
  background-color: #1a73e8;
  border-top: 1px solid rgba(0, 0, 0, 0.125);
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
}

.text-muted {
  color: #f5f5f5;
}

.card-body:hover {
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
  background-color: #f5f5f5;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
}

.add-new-class {
  align-items: center;
  border-radius: 8px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
}
