.baner{
    width: 100vw;
    height: 300px;
    display: flex;
}

.baner .left{
    min-width: 66vw;
    display: flex;
    align-items: center;
    margin-top: 40px;
    margin-bottom: 20px;
}

.baner .left .scroll_view{
    flex:1;
    display: flex;
    align-items: center;
    overflow: hidden;
}

.baner .left .scroll_view .left-container{
    display: flex;
    min-width: 100%;
    min-height: 100%;
    padding-left: 20px;
    padding-right: 20px;
    position: relative;
    transition: all 0.8s ease-in-out;
}

.baner .left .scroll_view .left-container .left-container-left{
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding-right: 20px;
    flex: 1;
}

.baner .left .scroll_view .left-container .left-container-left .baner_heading{
    color:#ff6929;
    font-size: 24px;
    font-weight: bold;
    text-shadow: 0px 0px 3px rgba(0,0,0,0.3);
    line-height: 30px;
    margin-bottom: 8px;
}

.baner .left .scroll_view .left-container .left-container-left p{
    color: white;
}

.baner .left .scroll_view .left-container .left-container-left >div:nth-child(3){
    display: flex;
    justify-content: center;
    margin-top: 30px;
}

.baner .left .scroll_view .left-container .left-container-left >div:nth-child(3) .btn-text{
    font-size: 16px;
    margin-right: 20px;
    margin-top: 10px;
}

.baner .left .ion-icon{
    color: white;
    font-size: 50px;
    font-weight: bold;
    opacity: 0.6;
    cursor: pointer;
}

.baner .left .scroll_view .left-container .left-container-right{
    display: flex;
    justify-content: center;
    align-items: center;
}

.baner .left .scroll_view .left-container .left-container-right img{
    width: 170px;
    height: 150px;
}

/* right */

.baner .right{
    min-width: 34vw;
    background-color: rgba(0,0,0,0.1);
    display: flex;
    flex-direction: column;
    padding: 20px;
    position: relative;
}

.baner .ft_video_controllers{
    position: absolute;
    top: 28px;
    left: 20px;
    right: 20px;
    bottom: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: black;
    cursor: pointer;
}

.baner .ft_video_controllers .play_button{
    width: 100px;
    height: 100px;
    color: #ff4d00a4;
}

.baner .ft_video_controllers .play_button:hover{
    color: #ff4d00d7;
}

.baner .ft_video_controllers p{
    color: white;
    opacity: 0.8;
    font-size: 13px;
}

.baner .ft_video_controllers label{
    color: white;
    font-weight: 400;
    opacity: 0.9;
}

/* .baner .right .baner_heading{
    color:white;
    font-size: 20px;
    text-shadow: 0px 0px 3px rgba(0,0,0,0.3);
}

.baner .right p{
    color:white;
    font-size: 13px;
} */