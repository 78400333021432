.closeCurrentSession{
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    background-color: rgba(0,0,0,0.05);
    backdrop-filter: blur(3px);
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding-top: 20px;
    >div{

        background-color: white;
        border-radius: 6px;
        padding-top: 25px;
        padding-left: 20px;
        padding-right: 20px;
        padding-bottom: 10px;
        display: flex;
        flex-direction: column;
        box-shadow: 0px 0px 3px rgba(0,0,0,0.3);
        >div{
            >label{
                font-size: 14px;
            }
            display: flex;
            flex-direction: row-reverse;
            align-items: center;
            >button{
                margin-left: 10px;
            }
            // >p{
            //     margin-left: 12px;
            //     font-size: 13px;
            //     box-shadow: 0px 0px 3px rgba(0,0,0,0.1);
            //     padding: 2px 10px;
            //     margin-top: 10px;
            //     border-radius: 6px;
            //     cursor: pointer;
            //     &:hover{
            //         opacity: 0.6;
            //     }
            // }
            // >p:nth-child(1){
            //     background-color: #00BBE7;
            //     color: white;
            // }
            // >p:nth-child(2){
            //     background-color: rgba(0,0,0,0.1);
            // }
        }
    }
}