@import "../../../global.scss";

*{
    scroll-behavior: smooth;
}

._help {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100vw;
    height: 100vh;
    z-index: 150;
    background-color: white;
    display: flex;
    flex-direction: column;
    font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande", "Lucida Sans Unicode", Geneva, Verdana,
        sans-serif;

    // header
    > :nth-child(1) {
        position: sticky;
        height: 50px;
        background-color: $nav_bar_color;
        display: flex;
        flex-direction: row;
        align-items: center;
        padding-left: 20px;
        padding-right: 20px;
        padding-top: 6px;

        // ft icon
        >:nth-child(1){
            margin-bottom: 4px;
        }

        > h3{
            color:white;
            font-weight: 400;
        }

        > label {
            font-weight: 100;
            color: white;
        }

        // title
        > :nth-child(2) {
            font-size: 20px;
            opacity: 0.9;
            margin-top: auto;
            margin-left: 10px;
        }

        > :nth-child(3) {
            margin-left: auto;
        }

        > a {
            margin-right: 20px;
            font-size: 14px;
            opacity: 0.8;
            transition: all 0.1s ease-in-out;
            color: white;
            margin-bottom: 5px;
            &:hover {
                opacity: 1;
                cursor: pointer;
                font-size: 16px;
            }
        }
    }

    // container
    > :nth-child(2) {
        flex: 1;
        display: flex;
        flex-direction: row;
        overflow-y: scroll;
        &::-webkit-scrollbar {
            display: none;
        }
        // content
        > :nth-child(1) {
            display: flex;
            flex-direction: column;
            flex: 1;
            padding: 10px 20px;
            overflow-y: scroll;
            &::-webkit-scrollbar {
                display: none;
            }
            .guide_li{
                display: block;
                list-style-type: disc;
                padding-bottom: 20px;
                >li{
                    font-size: 14px;
                    margin-left: 10px;
                    list-style-type: circle;
                    margin-left: 40px;
                }
            }
        }

        // right navbar
        > :nth-child(2) {
            width: 280px;
            border-left: 1px solid rgba(0, 0, 0, 0.1);
            padding-top: 20px;
            >ul{
                >li{
                    font-size: 14px;
                    margin-left: 30px;
                    line-height: 30px;
                    &:hover{
                        text-decoration: underline;
                    }
                }
                >ul{
                    >li{
                        font-size: 13px;
                        margin-left: 40px;
                        line-height: 35px;
                        &:hover{
                            text-decoration: underline;
                        }
                    }
                }
            }
        }
    }
}

.title {
    font-size: 16px;
    // opacity: 0.95;
}

p {
    font-size: 14px;
    // opacity: 0.9;
}

li {
    font-size: 14px;
    // opacity: 0.9;
}

.fm{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 370px;
    height: 300px;
    margin: 20px;
    margin-left: auto;
    margin-right: auto;
}

.arch_text1{
    font-size: 27px;
    font-weight: 900;
}