$color: #00ACDF;

#collaborate{
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    z-index: 99999;
    background-color: white;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    #header{
        background-color: $color;
        height: 50px;
        display: flex;
        padding: 0px 20px;
        align-items: center;
        flex-direction: row;
        color: white;
    }
    #header1{
        background-color: rgb(228, 228, 228);
        height: 35px;
        display: flex;
        padding: 0px 20px;
        align-items: center;
        flex-direction: row;
        border-bottom: 1px solid rgba(0,0,0,0.2);
        .button{
            
        }
    }
    #container{
        flex: 1;
        display: flex;
        flex-direction: row;
        #aside{
            width: 170px;
            background-color: rgb(228, 228, 228);
        }
        #aside1{
            width: 350px;
            overflow-y: scroll;
            border-right: 1px solid rgba(0,0,0,0.2);
        }
    }
}