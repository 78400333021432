.topic-block {
  margin-top: 70px;
  padding: 10px;
  -webkit-box-shadow: 0 0 15px 5px #ebebeb;
  box-shadow: 0 0 15px 5px #ebebeb;
  border-radius: 15px;
  border: 2px solid #1a73e8;
}
.topic-head {
  background-color: #1a73e8;
  padding: 8px 8px 8px 20px;
  color: #fff;
  font-size: 20px;
  margin-top: 40px;
  font-weight: 500;
}
.link-list li {
  width: 100%;
  height: 57px;
  background-color: #fdfdfd;
  border-bottom: 1px solid #e2dfdf;
}
.link-list li a {
  width: 100%;
  color: #5f5f5f;
  font-size: 16px;
  font-weight: 500;
  line-height: 25px;
  padding: 16px;
  display: block;
  height: 57px;
}
.link-list li:last-child {
  width: 100%;
  border-bottom: transparent;
}
.right-arrow {
  float: right;
  margin-top: 3px;
}
.btn-topic {
  cursor: pointer;
}
.topic-title {
  text-align: center;
  color: #1a73e8;
  font-weight: bold;
  text-decoration: underline;
}
.topic-progress-group {
  width: 100%;
  display: flex;
  flex-direction: row;
}
.tab {
  overflow: auto;
  border: 1px solid #ccc;
  background-color: #f1f1f1;
  width: 100%;
}

.tablinks {
  width: 50%;
  background-color: inherit;
  float: left;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 8px 8px;
  transition: 0.3s;
}
.tablinks-active {
  width: 50%;
  background-color: #ffff;
  color: #ccc;
  float: left;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 8px 8px;
  transition: 0.3s;
  border-bottom: 4px solid #1a73e8;
}
.expand-collapse {
  vertical-align: middle;
  margin-top: 14px;
}
.expand-item {
  width: 100%;
  display: flex;
  flex-direction: row;
}
.expand-start {
  width: 50%;
  text-align: center;
  font-size: 16px;
}
.expand-end {
  width: 50%;
  text-align: center;
  font-size: 16px;
}

.tab {
  overflow: auto;
  background-color: #f1f1f1;
  font-size: 16px;
  color: #999;
}

.tablinks-active {
  color: #333;
  background-color: #f3f3f3;
}

.list-group-item {
  position: relative;
  display: block;
  padding: 0px 0px;
}

.list-group {
  padding-left: 0;
  margin-bottom: 20px;
  padding-right: 0;
}
