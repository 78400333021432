/* quiz section */
.mt-25 {
  margin-top: 25px;
}
.top-question {
  display: -webkit-box;
}
.q-no {
  width: 50px;
  height: 50px;
  background-color: #1a73e8;
  display: block;
  color: #fff;
  margin-right: 10px;
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  line-height: 50px;
  -webkit-border-radius: 5px 5px 5px 5px;
  border-radius: 5px 5px 5px 5px;
}
.question-wrap {
  font-size: 16px;
  font-weight: bold;
  /* margin-left: -10px; */
}
.question-wrap p {
  color: #828282;
  margin-left: -3%;
  margin-top: 0.9%;
}
.answer-wrap {
  display: flex;
  background-color: #f5f5f5;
  width: 45%;
  cursor: pointer;
  padding: 10px;
  margin-left: 2%;
  /* -webkit-border-radius: 50px 50px 50px 50px;
  border-radius: 50px 50px 50px 50px; */
  -webkit-border-radius: 4px;
  border-radius: 4px;
  -webkit-box-shadow: 0 1px 3px 3px #dedede;
  box-shadow: 0 1px 3px 3px #dedede;
  align-items: center;
  /* -webkit-transition: .5s ease-in-out;
    -moz-transition: .5s ease-in-out;
    -o-transition: .5s ease-in-out;
    transition: .5s ease-in-out; */
  margin: 20px;
}
.answer-wrap:hover {
  background-color: #1a73e8;
}
.answer-wrap:hover .option-number {
  background-color: #fff;
  color: #1a73e8;
}
.answer-wrap:hover .option-text {
  color: #fff;
}
.option-number {
  width: 40px;
  height: 40px;
  background-color: #1a73e8;
  display: table;
  color: #fff;
  text-align: center;
  line-break: 12px;
  font-size: 16px;
  line-height: 40px;
  -webkit-border-radius: 5px 0 0 5px;
  /* border-radius: 50%; */
  border-radius: 4px;
  /* -webkit-transition: .5s ease-in-out;
     -moz-transition: .5s ease-in-out;
     -o-transition: .5s ease-in-out;
     transition: .5s ease-in-out; */
}
.option-text {
  padding-left: 15px;
  display: table;
  font-size: 14px;
  font-weight: bold;
  color: #828282;
  word-break: break-all;
  /* margin-top: 5px; */
  /* -webkit-transition: .5s ease-in-out;
    -moz-transition: .5s ease-in-out;
    -o-transition: .5s ease-in-out;
    transition: .5s ease-in-out; */
}
.option-text p {
  font-weight: bold;
  color: #828282;
  vertical-align: middle;
}
.quiz-button-pos {
  margin-left: 44%;
}
.quiz-button {
  background-color: #1a73e8;
  color: white;
  font-size: 20px;
  font-weight: bold;
  width: 22%;
  border-radius: 15px 15px 15px 15px;
}

.btn-wrapper {
  float: right;
  margin-top: 10px;
}
.option-row {
  display: flex;
  flex-direction: row;
}
.select-option {
  display: flex;
  width: 45%;
  background-color: #1a73e8;
  color: white;
  cursor: pointer;
  padding: 10px;
  /* margin-left: 2%; */
  -webkit-border-radius: 4px;
  border-radius: 4px;
  /* -webkit-border-radius: 50px 50px 50px 50px;
  border-radius: 50px 50px 50px 50px; */
  -webkit-box-shadow: 0 1px 3px 3px #dedede;
  box-shadow: 0 1px 3px 3px #dedede;
  align-items: center;
  margin: 20px;
  /* margin-top: 1.5%; */
}
.select-text {
  padding-left: 15px;
  display: table;
  font-size: 14px;
  font-weight: bold;
  color: white;
  word-break: break-all;
  margin-top: 0 !important;
  /* margin-top: 5px; */
  /* -webkit-transition: .5s ease-in-out;
  -moz-transition: .5s ease-in-out;
  -o-transition: .5s ease-in-out;
  transition: .5s ease-in-out; */
}

.select-number {
  width: 40px;
  height: 40px;
  background-color: #fff;
  display: table;
  color: #1a73e8;
  text-align: center;
  line-break: 12px;
  font-size: 18px;
  line-height: 40px;
  -webkit-border-radius: 4px;
  border-radius: 4px;
  /* -webkit-border-radius: 5px 0 0 5px;
  border-radius: 50%; */
  /* margin-top: 1.5%; */
  /* -webkit-transition: .5s ease-in-out;
    -moz-transition: .5s ease-in-out;
    -o-transition: .5s ease-in-out;
    transition: .5s ease-in-out; */
}
.btn-pagination {
  background-color: #1a73e8;
  color: #fff;
}
.prev-btn {
  margin-right: 12px;
}
.btn-disable {
  cursor: not-allowed;
}
.quiz-card {
  padding-bottom: 150px;
  border-radius: 4px;
  padding-top: 20px;
  padding-left: 30px !important;
  padding-right: 30px !important;
}
.q-img-section {
  height: 250px;
  background: #f9f9f9;
  border-radius: 10px;
  text-align: center;
}
.q-img-section img {
  height: 100%;
  object-fit: contain;
}

.answer-text {
  border-radius: 10px;
}
.quiz-title {
  font-weight: bold;
  color: #1a73e8;
  text-align: center;
  padding: 15px 0;
  font-size: 30px;
}
.image-question {
  transition: -webkit-transform 0.25s ease;
  transition: transform 0.25s ease;
  margin-top: 20px;
}

.image-question:active {
  -webkit-transform: scale(2);
  transform: scale(2);
}
/* quiz section end here */
