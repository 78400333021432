
.help-icon {
    margin-top: 4px;
    color: white;
}

.help-icon-view {
    position: absolute;
    background-color: white;
    z-index: 100;
    box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.4);
    font-size: 13px;
    padding: 6px 20px;
    border-radius: 6px;
    top: 32px;
    left:225px;
    display: none;
}

.help-icon:hover ~.help-icon-view {
    display: flex;
}

.help-icon-view:hover{
    display: flex;
}
