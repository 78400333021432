.contestCreated {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100vh;
  backdrop-filter: blur(6px);
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande", "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
  overflow: hidden; }
  .contestCreated > div {
    width: 700px;
    background-color: white;
    box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.3);
    display: flex; }
    .contestCreated > div .screen1 {
      flex: 1;
      display: flex;
      flex-direction: column; }
      .contestCreated > div .screen1 > div:nth-child(1) {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        height: 50px;
        background-color: #1b1b38;
        padding-top: 6px; }
        .contestCreated > div .screen1 > div:nth-child(1) > img {
          margin-bottom: 2px;
          margin-left: 10px; }
        .contestCreated > div .screen1 > div:nth-child(1) > :nth-child(2) {
          font-size: 16px;
          color: white;
          font-weight: 700;
          margin-left: auto;
          margin-right: 18px; }
    .contestCreated > div .screen2 {
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 20px 20px; }
      .contestCreated > div .screen2 > label:nth-child(1) {
        font-size: 20px;
        font-weight: 500; }
      .contestCreated > div .screen2 > label:nth-child(2) {
        font-size: 15px;
        margin-top: 8px;
        margin-bottom: 25px;
        opacity: 0.7;
        text-align: center; }
      .contestCreated > div .screen2 > div:nth-child(3) {
        height: 60px;
        width: 400px;
        border: 0.8px solid rgba(0, 0, 0, 0.3);
        border-radius: 8px;
        display: flex;
        flex-direction: row;
        align-items: center;
        background-color: white;
        box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.3);
        margin-bottom: auto; }
        .contestCreated > div .screen2 > div:nth-child(3) > textarea {
          flex: 1;
          padding-top: 8px;
          border: 0px;
          padding-left: 10px;
          padding-right: 10px;
          border: 0.8px solid rgba(0, 0, 0, 0.3);
          border-top-left-radius: 8px;
          border-bottom-left-radius: 8px;
          font-size: 13px; }
        .contestCreated > div .screen2 > div:nth-child(3) > i {
          height: 100%;
          display: flex;
          align-items: center;
          padding-left: 15px;
          padding-right: 15px;
          background-color: rgba(0, 0, 0, 0.1);
          font-size: 14px;
          cursor: pointer; }
      .contestCreated > div .screen2 > label:nth-child(4) {
        margin-top: 20px;
        margin-bottom: 20px;
        opacity: 0.4; }
      .contestCreated > div .screen2 > div:nth-child(5) {
        height: 40px;
        width: 400px;
        border: 0.8px solid rgba(0, 0, 0, 0.3);
        border-radius: 8px;
        padding-left: 10px;
        display: flex;
        flex-direction: row;
        align-items: center;
        white-space: nowrap;
        background-color: white;
        box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.3);
        margin-bottom: auto; }
        .contestCreated > div .screen2 > div:nth-child(5) > label {
          font-size: 13px;
          overflow: hidden;
          padding-top: 4px;
          opacity: 0.9; }
        .contestCreated > div .screen2 > div:nth-child(5) > div {
          margin-left: auto;
          background-color: rgba(0, 0, 0, 0.1);
          padding-right: 20px;
          padding-left: 20px;
          height: 100%;
          border-top-right-radius: 8px;
          border-bottom-right-radius: 8px;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer; }
          .contestCreated > div .screen2 > div:nth-child(5) > div > i {
            font-size: 13px; }
          .contestCreated > div .screen2 > div:nth-child(5) > div:hover {
            opacity: 0.4; }
      .contestCreated > div .screen2 > div:nth-child(6) {
        width: 100%;
        height: 45px;
        display: flex;
        flex-direction: row-reverse;
        align-items: center; }
        .contestCreated > div .screen2 > div:nth-child(6) > div {
          border: 1px solid rgba(0, 0, 0, 0.7);
          color: rgba(0, 0, 0, 0.7);
          border-radius: 10px;
          box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.5);
          font-size: 12px;
          font-weight: bold;
          padding: 5px 16px;
          cursor: pointer; }
