.view_leaders_model {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100vw;
    height: 100vh;
    backdrop-filter: blur(5);
    background-color: rgba(0, 0, 0, 0.2);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    backdrop-filter: blur(3px);
    > :nth-child(1) {
        background-color: white;
        box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.3);
        display: flex;
        flex-direction: column;
        min-width: 700px;
        min-height: 400px;
        ::-webkit-scrollbar {
            display: none;
        }
        //header
        > :nth-child(1) {
            line-height: 40px;
            background-color: #1b1b38;
            display: flex;
            align-content: center;
            padding-left: 20px;
            color: white;
            font-size: 16px;
        }
        //content
        > :nth-child(2) {
            display: flex;
            flex: 1;
            max-height: 500px;
            overflow: scroll;
            padding: 10px;
            > table {
                width: 100%;
                > tr {
                    border-bottom: 0.1px solid rgba(0, 0, 0, 0.3);
                    td{
                        text-align: right;
                    }
                    th{
                        text-align: right;
                    }
                }
            }
        }
        //footer
        > :nth-child(3) {
            height: 45px;
            display: flex;
            flex-direction: row-reverse;
            align-items: center;
            > div {
                margin-right: 20px;
                background-color: rgba(0, 0, 0, 0.1);
                border-radius: 10px;
                box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.3);
                font-size: 12px;
                font-weight: bold;
                padding: 5px 16px;
                color: rgba(0, 0, 0, 0.7);
                cursor: pointer;
                margin-bottom: 8px;
            }
        }
    }
}
