@import '../../../global.scss';
.class_dashboard {
    position: fixed;
    left: 0px;
    width: 100vw;
    background-color: white;
    z-index: 101;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: 300;
    overflow-y: scroll;
    transition: all 0.3s ease-in-out;
    &::-webkit-scrollbar {
        display: none;
    }
    // header
    >div:nth-child(1) {
        height: 45px;
        background-color: #3F3D56;
        border-top: 1px solid rgba(245, 245, 245, 0.2);
        display: flex;
        flex-direction: row;
        align-items: center;
        padding-left: 20px;
        padding-right: 20px;
        color: white;
        >label {
            margin-top: auto;
            margin-bottom: auto;
        }
        >:nth-child(2) {
            margin-left: auto;
            margin-right: 20px;
        }
        >:nth-child(3) {
            margin-right: 20px;
            font-size: 14px;
        }
        >i {
            margin-top: auto;
            margin-bottom: auto;
            cursor: pointer;
        }
        >.minus {
            margin-bottom: 18px;
            margin-right: 14px;
        }
        >.maximize {
            margin-right: 14px;
        }
    }
    //class details
    >:nth-child(2) {
        overflow: hidden;
        transition: 0.3s all ease-in-out;
        box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.3);
        border-radius: 10px;
        display: flex;
        flex-direction: row;
        >div:nth-child(1) {
            display: flex;
            flex-direction: row;
        }
        >div {
            flex: 1;
            >p {
                font-size: 14px;
                text-align: justify;
            }
            >div {
                flex: 1;
            }
            >div>label {
                font-size: 13.5px;
                font-weight: 500;
                display: block;
                margin-bottom: 10px;
                >label {
                    font-weight: 500;
                    font-size: 13.5px;
                    opacity: 0.7;
                }
            }
        }
    }
    //dashboard content
    > :nth-child(3) {
        height: 340px;
        display: flex;
        flex-direction: row;
        // donut chart
        > :nth-child(1) {
            display: flex;
            flex: 1;
            flex-direction: column;
            height: 315px;
            box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.3);
            background-color: white;
            border-radius: 10px;
            margin: 10px;
            padding: 10px;
        }
        // horizontal bar chart
        > :nth-child(2) {
            flex: 1;
            height: 314px;
            background-color: white;
            box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.3);
            border-radius: 10px;
            margin-top: 10px;
        }
        //pie chart1
        >:nth-child(3) {
            flex: 1;
            height: 315px;
            background-color: white;
            box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.3);
            border-radius: 10px;
            margin-left: 10px;
            margin-top: 10px;
            padding: 10px;
        }
        //pie chart 2
        >:nth-child(4) {
            flex: 1;
            height: 315px;
            background-color: white;
            box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.3);
            border-radius: 10px;
            margin-left: 10px;
            margin-right: 10px;
            margin-top: 10px;
            padding: 10px;
        }
    }
    // row 2
    >:nth-child(4) {
        height: 350px;
        box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.3);
        border-radius: 10px;
        margin: 10px;
    }
    // row 3
    >:nth-child(5) {
        height: 400px;
        box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.3);
        border-radius: 10px;
        margin: 20px 10px;
    }
    // row 4
    >:nth-child(6) {
        box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.3);
        border-radius: 10px;
        margin: 20px 10px;
        padding: 5px;
        >div{
            display: flex;
            margin: 10px;
            >label {
                display: flex;
            }
            >div{
                margin-left: auto;
                font-size: 14px;
                font-weight: 300;
                cursor: pointer;
                color: #009CCE;
                padding-bottom: 2px;
                >input{
                    margin-top: 8px;
                    margin-right: 8px;
                }
            }
        }
    }
    // row 5
    >:nth-child(7) {
        box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.3);
        border-radius: 10px;
        margin: 20px 10px;
        padding: 5px;
        >div{
            display: flex;
            margin: 10px;
            >label {
                display: flex;
            }
            >div{
                margin-left: auto;
                font-size: 14px;
                font-weight: 300;
                cursor: pointer;
                color: #009CCE;
                padding-bottom: 2px;
                >input{
                    margin-top: 8px;
                    margin-right: 8px;
                }
            }
        }
    }
}