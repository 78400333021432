.idleTimeout {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100vw;
    height: 100vh;
    backdrop-filter: blur(6px);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 150;

    > :nth-child(1) {
        height: 150px;
        background-color: white;
        border-radius: 20px;
        box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.3);
        display: flex;
        flex-direction: column;
        padding: 20px 30px;
        > :nth-child(1) {
            flex: 1;
            display: flex;
            align-items: center;
            > label {
                font-size: 15px;
                opacity: 0.9;
            }
        }
        > :nth-child(2) {
            display: flex;
            justify-content: flex-end;
            > label {
                font-size: 13px;
                padding: 4px 20px;
                border: 1px solid rgba(0, 0, 0, 0.3);
                border-radius: 10px;
                cursor: pointer;
                &:hover {
                    opacity: 0.5;
                }
            }
        }
    }
}
