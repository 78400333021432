/* .completed-bar {
    background-color: #1ba841;
    height: 25px;
    width: 100px;
    margin-top: 15px;
    text-align: center;
    color:#fff;
    font-weight:500;
} */
.completed-bar {
  background-color: #1ba841;

  width: 120px;

  margin-top: 12px;

  text-align: center;

  color: #fff;

  font-weight: 500;

  margin-bottom: 12px;
}
/* .inprogress-bar {
  background-color: #ff8400;
  height: 25px;
  width: 100px;
  margin-top: 15px;
  text-align: center;
  color: #fff;
  font-weight: 500;
} */
.inprogress-bar {
  background-color: #ff8400;
  width: 120px;

  margin-top: 12px;

  text-align: center;

  color: #fff;

  font-weight: 500;

  margin-bottom: 12px;
}
/* .pending-bar {
  background-color: #5e77a5;
  height: 25px;
  width: 100px;
  margin-top: 15px;
  text-align: center;
  color: #fff;
  font-weight: 500;
} */
.pending-bar {
  background-color: #5e77a5;
  width: 120px;

  margin-top: 12px;

  text-align: center;

  color: #fff;

  font-weight: 500;

  margin-bottom: 12px;
}
/* .ready-bar {
  background-color: #23e000;
  height: 25px;
  width: 100px;
  margin-top: 15px;
  text-align: center;
  color: #fff;
  font-weight: 500;
} */
.ready-bar {
  background-color: #23e000;
  width: 120px;

  margin-top: 12px;

  text-align: center;

  color: #fff;

  font-weight: 500;

  margin-bottom: 12px;
}
