@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");
* {
  scroll-behavior: smooth; }

._help {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100vh;
  z-index: 150;
  background-color: white;
  display: flex;
  flex-direction: column;
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande", "Lucida Sans Unicode", Geneva, Verdana, sans-serif; }
  ._help > :nth-child(1) {
    position: sticky;
    height: 50px;
    background-color: #3F3D56;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 6px; }
    ._help > :nth-child(1) > :nth-child(1) {
      margin-bottom: 4px; }
    ._help > :nth-child(1) > h3 {
      color: white;
      font-weight: 400; }
    ._help > :nth-child(1) > label {
      font-weight: 100;
      color: white; }
    ._help > :nth-child(1) > :nth-child(2) {
      font-size: 20px;
      opacity: 0.9;
      margin-top: auto;
      margin-left: 10px; }
    ._help > :nth-child(1) > :nth-child(3) {
      margin-left: auto; }
    ._help > :nth-child(1) > a {
      margin-right: 20px;
      font-size: 14px;
      opacity: 0.8;
      transition: all 0.1s ease-in-out;
      color: white;
      margin-bottom: 5px; }
      ._help > :nth-child(1) > a:hover {
        opacity: 1;
        cursor: pointer;
        font-size: 16px; }
  ._help > :nth-child(2) {
    flex: 1;
    display: flex;
    flex-direction: row;
    overflow-y: scroll; }
    ._help > :nth-child(2)::-webkit-scrollbar {
      display: none; }
    ._help > :nth-child(2) > :nth-child(1) {
      display: flex;
      flex-direction: column;
      flex: 1;
      padding: 10px 20px;
      overflow-y: scroll; }
      ._help > :nth-child(2) > :nth-child(1)::-webkit-scrollbar {
        display: none; }
      ._help > :nth-child(2) > :nth-child(1) .guide_li {
        display: block;
        list-style-type: disc;
        padding-bottom: 20px; }
        ._help > :nth-child(2) > :nth-child(1) .guide_li > li {
          font-size: 14px;
          margin-left: 10px;
          list-style-type: circle;
          margin-left: 40px; }
    ._help > :nth-child(2) > :nth-child(2) {
      width: 280px;
      border-left: 1px solid rgba(0, 0, 0, 0.1);
      padding-top: 20px; }
      ._help > :nth-child(2) > :nth-child(2) > ul > li {
        font-size: 14px;
        margin-left: 30px;
        line-height: 30px; }
        ._help > :nth-child(2) > :nth-child(2) > ul > li:hover {
          text-decoration: underline; }
      ._help > :nth-child(2) > :nth-child(2) > ul > ul > li {
        font-size: 13px;
        margin-left: 40px;
        line-height: 35px; }
        ._help > :nth-child(2) > :nth-child(2) > ul > ul > li:hover {
          text-decoration: underline; }

.title {
  font-size: 16px; }

p {
  font-size: 14px; }

li {
  font-size: 14px; }

.fm {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 370px;
  height: 300px;
  margin: 20px;
  margin-left: auto;
  margin-right: auto; }

.arch_text1 {
  font-size: 27px;
  font-weight: 900; }
