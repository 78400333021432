.class-cards {
  background-color: #fff;
  margin: auto;
  width: 90%;
  height: 144px;
  position: relative;
  -webkit-box-shadow: 0 0 15px 5px #ebebeb;
  box-shadow: 0 0 15px 5px #ebebeb;
  margin-bottom: 40px;
  border-radius: 15px;
  border: 2px solid #1a73e8;
}
.class-container {
  /* height:60vh; */
}
.ic-del {
  position: absolute;
  font-size: 10px;
  right: -16px;
  top: -8px;
  margin: 15px;
  padding: 8px;
  margin-right: 23px;
  margin-top: 15px;
  background-color: #1a73e8 !important;
  border-radius: 5px;
  color: #ffffff;
}

.class-name-wrap {
  position: relative;
  padding: 10px;
  top: 33%;
  transform: translateY(-50%);
  text-align: left;
  height: 140px;
  font-family: "Times New Roman", Times, serif;
}

.class-name-wrap h4 {
  color: #afaaaa;
  font-size: 20px;
}
.add-new-btn {
  float: right;
  margin: 25px 45px 25px;
  background-color: #1a73e8 !important;
}

.class-datas {
  color: #afaaaa;
  margin: 0;
  position: absolute;
  width: 90%;
  padding-bottom: 6px;
  font-size: 15px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  text-align: left;
}
.item-data {
  font-weight: bold;
  color: black;
}
.class-btn {
  position: absolute;
  left: 0px;
}
.year-filter {
  width: 160px;
  position: absolute;
  left: 500px;
  top: 80px;
}

.card-footer {
  padding: 0.4rem 0.75rem;
  background-color: #1a73e8;
  border-top: 1px solid rgba(0, 0, 0, 0.125);
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
}

.text-muted {
  color: #f5f5f5;
}

.card-body:hover {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  background-color: #f5f5f5;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
}

.add-new-class {
  align-items: center;
  border-radius: 8px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
}

.card-title {
  margin-top: 6px;
  margin-bottom: 0px;
}

.dates {
  margin-top: 0px;
}

#card {
  z-index: 1;
}

._addClass{
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: auto;
  margin-right: 20px;
  background-color: white;
  color: #0056c7;
  padding: 4px 15px;
  border-radius: 8px;
  box-shadow: 0px 0px 3px rgba(0,0,0,0.3);
  z-index: 100;
  cursor: pointer;
}

.class_list_item_info_icon {
  padding-left: 10px;
  font-size: 18.5px;
  color: rgba(0, 0, 0, 0.9);
  cursor: pointer;
  position: absolute;
  top: 0px;
  right: 20px;
}

.filter_dropdown{
  height:40px;
  box-shadow: 0px 0px 3px rgba(0,0,0,0.3);
  border-radius:10px;
  /* position:absolute;
  top:85px;
  right:13px; */
  display:flex;
  flex-direction:row;
  align-items:center;
  background-color: #0056c7;
  color: white;
  border: 0.4px solid rgba(245,245,245,0.5);
  z-index: 100;
}

.filter_dropdown_1{
  height:40px;
  border: 1px solid #1A73E8;
  box-shadow: 0px 0px 3px rgba(0,0,0,0.3);
  border-radius:10px;
  /* position:absolute;
  top:85px;
  right:126px; */
  display:flex;
  flex-direction:row;
  align-items:center;
  gap: 20px;
  padding: 0px 20px;
  font-size: 14px;
  padding-top: 6px;
  background-color: #0056c7;
  color: white;
  border: 0.4px solid rgba(245,245,245,0.5);
  z-index: 100;
}

.filter_dropdown_1_item{
  cursor: pointer;
  font-weight: 500;
  color: white;
}

.filter_dropdown_1_item:hover{
  text-decoration: underline;
  color: white;
}

.active{
  color: white;
  text-decoration: underline;
}

.filter_dropdown >:nth-child(1) >label{
  color: black;
  font-weight: 450;
}

.filter_dropdown >:nth-child(1) >i{
  color: white;
}

.filter_dropdown:hover >:nth-child(1) >:nth-child(3){
  display:flex;
}

.filter_dropdown:hover >:nth-child(1)  >:nth-child(2){
  transition: 0.3s;
  transform: rotate(180deg);
}

.filter_dropdown >:nth-child(1) >:nth-child(1){
  flex:1;
  text-align:center;
  font-size:15px;
  margin:10px;
}

.filter_dropdown >:nth-child(1)  >:nth-child(2){
  margin:10px;
  font-size:13px;
  cursor:pointer;
}

.filter_dropdown >:nth-child(1) >:nth-child(3){
  display:none;
  position:absolute;
  min-width: 180px;
  background-color:white;
  border: 1px solid rgba(0,0,0,0.3);
  border-radius:10px;
  padding:20px;
}

.filter_dropdown >:nth-child(1) >:nth-child(3) >ul >li{
  margin-bottom:8px;
  font-size:13px;
  font-weight:bold;
  cursor: pointer;
  opacity: 0.6;
  color: black;
}

.card_search{
  height:40px;
  width: 240px;
  background-color: #0056c7;
  box-shadow: 0px 0px 3px rgba(0,0,0,0.3);
  border-radius:10px;
  /* position:absolute;
  top:85px;
  right:390px; */
  display:flex;
  flex-direction:row;
  align-items: center;
  border: 0.4px solid rgba(245,245,245,0.5);
  z-index: 100;
  color: white;
}

.card_search >i{
  margin: 10px;
  color: white;
}

.card_search > input{
  background-color: transparent;
  border: 0px;
  outline-width: 0px;
  font-size: 15px;
  color: white;
}

.card_search > input::placeholder {
  color: white;
  opacity: 1; /* Firefox */
}