.quiz_view{
    position: fixed;
    left: 45px;
    bottom: 80px;
    right: 0px;
    top:45px;
    left:0px;
    width: 100vw;
    background-color: white;
    z-index: 350;
    transition: all 0.3s ease-in-out;
    overflow-y: scroll;

    > .quiz_header{
        min-height: 45px;
        background-color: #3F3D56;
        border-top: 1px solid rgba(245,245,245,0.2);
        display: flex;
        flex-direction: row;
        align-items: center;
        padding-left: 20px;
        padding-right: 20px;
        color:white;
        >label{
            margin-top: auto;
            margin-bottom: auto; 
        }
        >:nth-child(2){
            margin-left: auto;
            margin-right: 20px;
        }
        >:nth-child(3){
            margin-right: 20px;
            font-size: 14px;
        }
        >i{
            margin-top: auto;
            margin-bottom: auto;
            cursor: pointer;
        }
        >.minus{
            margin-bottom: 18px;
            margin-right: 14px;
        }
        >.maximize{
            margin-right: 14px;
        }
    }
}