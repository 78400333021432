.print-content-container{
    width: 100%;
    margin: 0 auto;
    padding: 5% 4% 0 4%;
}
.qr-code{
    text-align: center;
    margin: 0 auto;
    display: block;
    margin-top: 150px;
}
.invite-details{
    font-size: 28px;
    margin-bottom: 15px;
}
.invite-text{
    font-size: 28px;
    text-align: center;
}
.print-text span {
    font-size: 28px;
    text-align: center;
    }

.print-class-name {
    color: black;
    font-size: 20px;
}
.print-class-decription {
    color: black;
    text-align: left;
    font-size: 20px;
    display: inline-block;
}

.print-date{
    font-size: 20px;
}